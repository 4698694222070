import React from 'react';
import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TransferList from '../components/transferList';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
  container: {
    padding: '10px', //em = dynamic units?
    margin: '10px',
    backgroundColor: 'rgb(240, 240, 240)',
  },
};

interface EventDisplayProps {
  updateEvents: any,
  saveEvent: any,
  deleteEvent: any,
  //key: number,
  index: number,
  event: any,
}
interface EventDisplayState {
  event: any,
  open: boolean,
  hotPoints: any,
  modalOpen: boolean,
}

class EventDisplay extends React.Component <EventDisplayProps, EventDisplayState> {
  constructor(props: any) {
    super(props);
    this.state = {
      event: this.props.event,
      open: this.props.event.id ? false : true,
      hotPoints: [],
      modalOpen: false,
    };
    console.log('made event '+this.props.index, this.props.event);
  }

  componentDidMount() {

  }

  onTextChange = (event: any) => {
    const updatedEvent = this.state.event;
    updatedEvent.name = event.target.value;
    this.setState({event: updatedEvent});
    this.props.updateEvents(this.state.event, this.props.index);
  }

  setModalOpen = (modalOpen: boolean) => {
    this.setState({modalOpen});
  }

  onSaveEventClick = () => {
    console.log('clicked saveEvent on', this.state.event)
    this.props.saveEvent(this.state.event);
    this.setState({open: false})
  }

  onDeleteEventCick = () => {
    console.log('clicked delete on', this.state.event)
    this.props.deleteEvent(this.state.event);
  }

  updateEvent = (left: Array<string>) => {
    this.setState({event: {...this.state.event, namesOfLayersToDisplay: left}});
  }

  generateRightSide = () => {
    // get names not displayed
    const selectedSvgs: Array<string> = this.state.event.namesOfLayersToDisplay
    const allLayers: Array<string> = this.state.event.svg.svgLayers.items.map((s3Item: any) => s3Item.objectName)
    const right = allLayers.filter((layerName: string) => !selectedSvgs.includes(layerName));
    console.log('right', right)
    return right;
  }

  render() {
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container>
        <Modal
          className={styles.modal}
          open={this.state.modalOpen}
          onClose={() => {
            this.setModalOpen(false)
          }}
        >
          <Container className={styles.container}>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="name">Sequence of Operations</InputLabel>
              <Input
                id="sequenceOfOperations"
                name="sequenceOfOperations"
                type="text"
                multiline
                value={this.state.event.sequenceOfOperations ? this.state.event.sequenceOfOperations : ''}
                onChange={(event) => {
                  this.setState({event: {...this.state.event, sequenceOfOperations: event.target.value}});
                  console.log('state after update', this.state.event)
                }
                }
              />
            </FormControl>
            <Button
              id={'closeAndSaveSequenceOfEventsButton'}
              variant="contained"
              color="primary"
              className={styles.button}
              onClick={() => {this.onSaveEventClick(); this.setModalOpen(false)}}
            >
              Save and Close
            </Button>
          </Container>
        </Modal>
        <TextField
          id="eventDisplayHeader"
          value={this.state.event.name}
          onChange={(event: any) => this.onTextChange(event)}
        />
        <Button
          id={'saveEventButton'}
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={this.onSaveEventClick}
        >
          Save
        </Button>
        <Button
          id={'sequenceOfOperationsButton'}
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={() => {this.setModalOpen(true)}}
        >
          Sequence of Operations
        </Button>
        <Button
          id={'minimizeEventButton'}
          variant="contained"
          className={styles.button}
          onClick={() => {this.setState({open: !this.state.open})}}
        >
          {this.state.open ? 'Minimize' : 'Expand'}
        </Button>
        <Button
          id="deleteEventButton"
          variant="outlined"
          color="secondary"
          className={styles.button}
          startIcon={<DeleteIcon />}
          onClick={this.onDeleteEventCick}
        >
          Delete Event
        </Button>
        {this.state.open
          && <TransferList
            updateEvent={this.updateEvent}
            left={this.state.event.namesOfLayersToDisplay}
            right={this.generateRightSide()}
          />
        }
      </Container>
    );
  };
}

export default withStyles(styles)(EventDisplay);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { getRLItem } from '../graphql/queries';
import { deleteRLItem } from '../graphql/mutations';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    padding: '10px',
  }
};

interface RLItemProps {
  rlItem: any,
  setActiveScreen: any,
  updateSidebar: any,
  deleteRlItem: any,
}

interface RLItemState {
}

class RLItem extends React.Component <RLItemProps, RLItemState> {
  constructor(props: any) {
    super(props);
  }

  editRlItem = (rlItem: any) => {
    this.props.setActiveScreen('newRlItemForm', {rlItem});
  }

  deleteRlItem = async (rlItem: any) => {
    return await API.graphql(graphqlOperation(
      deleteRLItem,
      {
        input: {
          id: rlItem.id
        }}
        // @ts-ignore
      )).then((rlItem: any) => {
        this.props.deleteRlItem(rlItem.data.deleteRLItem);
        console.log('deleted rlItem', rlItem);
      }).catch((err: any) => {
        console.log('Error deleting new rlItem!', err);
        return err;
      }
    );
  }

  render(){
    //@ts-ignore
    const classes = this.props.classes;
    return (
      <Paper
        className={classes.root}
      >
        <Typography>
          {this.props.rlItem.name}
        </Typography>
        <Typography>
          {this.props.rlItem.category}
        </Typography>
        <Typography>
          {this.props.rlItem.number}
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          id="editRlItemButton"
          onClick={() => this.editRlItem(this.props.rlItem)}
        >
          <Typography>
            Edit
          </Typography>
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          id="deleteRlItemButton"
          onClick={() => this.deleteRlItem(this.props.rlItem)}
        >
          <Typography>
            Delete
          </Typography>
        </Button>
      </Paper>
    );
  }
}

export default withStyles(styles)(RLItem);

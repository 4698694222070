import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Pagination from '@material-ui/lab/Pagination';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { createHotPoint, createEvent, updateHotPoint, updateEvent, createHotPointEventLink } from '../graphql/mutations';
import { getHotPointsForSvg, getEventsForSVG } from '../graphql/customQueries';
import { deleteEventAndLinks, deleteSVGFromSite, deleteHotPointAndImagesAndLinks } from '../graphql/graphqlFunctions';

import xml2js from 'xml2js';
import Papa from 'papaparse';

import HotPointListItem from '../components/hotPointLineItem';
import EventDisplay from './eventDisplay';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
  container: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },
};

interface SVGDisplayProps {
  setActiveScreen: any,
  deleteSvgCallback: any,
  siteId: string,
  siteOwnerCognitoIdentityId: string,
  events: any,
  svg: any,
}
interface SVGDisplayState {
  displayCsv: boolean,
  displayHotPoints: boolean,
  hotPointPage: number,
  displayEvents: boolean,
  hotPoints: any,
  events: any,
  idLayerName: string,
  rawCsv: string,
}

class SVGDisplay extends React.Component <SVGDisplayProps, SVGDisplayState> {
  constructor(props: any) {
    super(props);
    console.log('svgDisplayProps', this.props)
    this.state = {
      displayCsv: false,
      displayHotPoints: false,
      hotPointPage: 1 ,
      displayEvents: false,
      events: this.props.events ? this.props.events : [],
      hotPoints: [],
      idLayerName: 'Bubble Numbers',
      rawCsv: '',
    }
  }

  componentDidMount() {
    API.graphql(
      graphqlOperation(
        getHotPointsForSvg,
        {
          id: this.props.siteId,
          //hotPointFilter: ModelHotPointFilterInput,
          //eventFilter:  {projectId: {eq: this.props.project.id}}},
          hotPointLimit: 1000,
          eventLimit: 1,
          svgFilter: {id: {eq: this.props.svg.id}}
          //nextEventToken: String,
          //nextHotPointToken: String,
        },
      )
      // @ts-ignore
    ).then((data: any) => {
      console.log('data new',data);
      if(!data.data.getSite.associatedSvgs.items
        || !data.data.getSite.associatedSvgs.items[0]
        || !data.data.getSite.associatedSvgs.items[0].events
        || !data.data.getSite.associatedSvgs.items[0].events.items[0]
        || !data.data.getSite.associatedSvgs.items[0].events.items[0].hotPoints
      ) {
        return;
      }
      const hotPoints = data.data.getSite.associatedSvgs.items[0].events.items[0].hotPoints.items
      console.log('state after db hit',hotPoints)
      hotPoints.sort((a: any, b: any) => {
        return ('' + a.hotPoint.componentNumber).localeCompare(b.hotPoint.componentNumber);
      });
      console.log('sortedHPs',hotPoints)
      this.setState({hotPoints});
    }).catch((err: any) => {
      // console.log({error: 'Error createHotPoint: '+ err.error.message});
      console.log('Error getHotPointsForSvg:', err);
    });
  }

  httpGet = async (theUrl: string) => {
    const promise = new Promise((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest(); //not supporting IE 6 or below
      xmlhttp.onreadystatechange = () => {
          if (xmlhttp.readyState==4 && xmlhttp.status==200) {
              resolve(xmlhttp.responseText);
          }
      }
      xmlhttp.open("GET", theUrl, false);
      xmlhttp.send();
    });
    const data = await promise;
    return data;
  }
  updateEvents = (updatedEvent: any, eventIndex: number) => {
    const events = this.state.events.map((event:any, index: number) => {
      if(eventIndex == index) {
        return updatedEvent;
      } else {
        return event;
      }
    });
    this.setState({events})
  }
  deleteEvent = async (deletedEvent: any, index: number) => {
    console.log('deleted event incomming', deletedEvent, index);
    deleteEventAndLinks(deletedEvent.id);
    this.getEventsForSvg(this.props.siteId, this.props.svg.id);
  };
  deleteSvg = async () => {
    // add modal and logic for recursive event delete
    deleteSVGFromSite(this.props.svg.id, this.props.deleteSvgCallback);

  };
  createHotPoints = async () => {
    // this section will need to be updated if not using AI
    await this.getEventsForSvg(this.props.siteId, this.props.svg.id);
    const s3Item = this.state.events[0].svg.svgLayers.items.filter((s3Item: any) => {
      if (s3Item.objectName.includes(this.state.idLayerName.replace(/ /g, '_'))) {
        return s3Item
      }
    });
    const s3Id = s3Item[0].s3ObjectId
    console.log('s3id to get', s3Id)

    const hotPointSvg: any = await Storage.get(s3Id, {level: 'protected', identityId: this.props.siteOwnerCognitoIdentityId}).then(async (url) => {
      const data = await this.httpGet(url.toString());
      console.log('url from Storage', url)
      return data;
    }).catch((err) => {
      console.log('Error getting SVG!',err);
      return {error: err};
    })
    if(!hotPointSvg.error) {
      console.log('hotPointsvg', hotPointSvg);
      let parser = new xml2js.Parser();
      const parsedSvgXml = await parser.parseStringPromise(hotPointSvg).then(result => {
        console.dir(result);
        console.log('Done', result);
        return result;
      })
      .catch(function (err) {
        // Failed
        console.error('Error from xml parser!', err)
        return err
      });
      const hotPoints: any[] = [];
      //dealing with hotpoints coming in multiple formats
      if(parsedSvgXml.svg.g[0].g[0].g) {
        const rawBubbles = parsedSvgXml.svg.g[0].g;
        for(let x = 0; x < rawBubbles.length; x++) {
          for(let y = 0; y < rawBubbles[x].g.length; y++) {
            if(Object.keys(rawBubbles[x].g[y]).indexOf('text') != -1) {
              hotPoints.push({hotPoint:{componentNumber: rawBubbles[x].g[y].text[0]['_'], isChanged: true}});
            }
          }
        }//end outer for
      }
      else if(parsedSvgXml.svg.g[0].text) {
        const rawBubbles = parsedSvgXml.svg.g[0].text;
        for(let x = 0; x < rawBubbles.length; x++) {
          hotPoints.push({hotPoint:{componentNumber: rawBubbles[x]['_'], isChanged: true}});
        }//end outer for
      }
      hotPoints.sort((a: any, b: any) => {
        return ('' + a.hotPoint.componentNumber).localeCompare(b.hotPoint.componentNumber);
      });
      //this.setState({hotPoints});'
      this.saveInitialHotPoints(hotPoints);
    }
    else if(hotPointSvg.error) {
      console.error('Error!', hotPointSvg.error);
    }
  };
  parseHotPointsFromCSV = async (input: string) => {
    // componentRL, RLCategory, RLNumber, description, manufacturer, part number, stockCode, electricalAddress, componentApplication, referenceTag, settings
    const parsedInput: any = Papa.parse(input.trim());
    console.log('parsedInput', parsedInput);
    const hotPoints = [parsedInput.data.length];
    for(let x = 0; x < parsedInput.data.length; x++) {
      hotPoints[x] = {hotPoint:{}};
      hotPoints[x].hotPoint.componentNumber = parsedInput.data[x][0];
      hotPoints[x].hotPoint.rlNumber = parsedInput.data[x][2];
      hotPoints[x].hotPoint.description = parsedInput.data[x][3];
      hotPoints[x].hotPoint.manufacturer = parsedInput.data[x][4];
      hotPoints[x].hotPoint.partNumber = parsedInput.data[x][5];
      hotPoints[x].hotPoint.stockCode = parsedInput.data[x][6];
      hotPoints[x].hotPoint.electricalAddress = parsedInput.data[x][7];
      hotPoints[x].hotPoint.componentApplication = parsedInput.data[x][8];
      hotPoints[x].hotPoint.referenceTag = parsedInput.data[x][9];
      hotPoints[x].hotPoint.settings = parsedInput.data[x][10];
      //not doing anything with image(11) currently
    }
    //sort just in case hp are out of order
    hotPoints.sort((a: any, b: any) => {
      return ('' + a.hotPoint.componentNumber).localeCompare(b.hotPoint.componentNumber);
    });
    this.setState({hotPoints});
    this.saveInitialHotPoints(hotPoints);
  };
  updateHotPoints = (hotPointObject: any) => {
    const hotPoint = hotPointObject.hotPoint;
    // hotpointLineItem uses hotpoint while here is hotpoint.hotpoint
    const hotPoints: any[] = [this.state.hotPoints.length];
    for(let x = 0; x < this.state.hotPoints.length; x++) {
      const hp = this.state.hotPoints[x]; //hotpoint.hotPoint to access data
      if(hotPoint.componentNumber === hp.hotPoint.componentNumber) {
        hotPoint.isChanged = true;
        hotPoint.updatedAt = Date.now();
        hotPoint.createdAt = hotPoint.createdAt ? hotPoint.createdAt : hotPoint.updatedAt;
        hotPoints[x] = {hotPoint};//transform to weirdness
      }
      else {
        hotPoints[x] = hp;
      }
    }

    console.log('current hotPoints for update hotpoints',hotPoint,'gggggggggg', hotPoints);
    this.setState({hotPoints});
  };
  saveHotPoints = async () => {
    console.log('current hotPoints for saveHotPoints and events', this.state.hotPoints, this.state.events);
    const promises: Promise<any>[] = [];

    // put image uploading logic here, then stuff IDs below

    this.state.hotPoints.map((currentHotPoint: any, index:number) => {
      if (currentHotPoint.hotPoint.isChanged) {
        promises.push(new Promise((resolve, reject) => {
            const input = {
              id: currentHotPoint.hotPoint.id,
              updatedAt: Date.now(),
              componentNumber: currentHotPoint.hotPoint.componentNumber,
              description: currentHotPoint.hotPoint.description,
              manufacturer: currentHotPoint.hotPoint.manufacturer,
              partNumber: currentHotPoint.hotPoint.partNumber,
              stockCode: currentHotPoint.hotPoint.stockCode,
              electricalAddress: currentHotPoint.hotPoint.electricalAddress,
              componentApplication: currentHotPoint.hotPoint.componentApplication,
              rlNumber: currentHotPoint.hotPoint.rlNumber,
              referenceTag: currentHotPoint.hotPoint.referenceTag,
              settings: currentHotPoint.hotPoint.settings,
            };
            API.graphql(
              graphqlOperation(
                updateHotPoint,
                {input},
              )
              // @ts-ignore
            ).then((hotPoint: any) => {
              const hp = hotPoint.data.updateHotPoint;
              resolve({message: 'hotPoint update: '+hp.id, hotPoint: hp});
            }).catch((err: any) => {
              console.log('error', err)
              reject();
            });
        }));
      }
    });

    const results = await Promise.all(promises).then((results: any) => {
      const newHotPoints = results.map((result: any) => {
        const hp = result.hotPoint;
        hp.isChanged = false;
        return hp;
      });
      const hotPointsToUpdate = this.state.hotPoints;
      newHotPoints.map((newHotPoint: any) => {
        const hpIndex = this.state.hotPoints.findIndex((hotPoint: any) => {
          return newHotPoint.componentNumber === hotPoint.componentNumber;
        });
        hotPointsToUpdate[hpIndex] = newHotPoint;
      })
      this.setState({hotPoints: hotPointsToUpdate});
      return results;
    }).catch((errs: any) => {
      console.log('boo')
      return errs
    });
    console.log('final state', this.state);
  };
  deleteHotPoints = async () => {
    //similar logic to deleting events
    const hotPointsToDelete = this.state.hotPoints;
    this.setState({hotPoints: []});
    hotPointsToDelete.map(async (hotPointToDelete: any ) => {
      deleteHotPointAndImagesAndLinks(hotPointToDelete.hotPoint.id);
    });
  }
  saveEvent = async (event: any) => {
    console.log('event to save', event)
    delete event.svg;
    delete event.hotpoints
    if(event.id) {
      const input: any = {
        ...event,
        updatedAt: Date.now(),
      }
      API.graphql(graphqlOperation(
        updateEvent,
        { input }
        // @ts-ignore
        )).then((event: any) => {
          const events = this.state.events.map((ev: any) => {
            if(event.data.updateEvent.id === ev.id) {
              return event.data.updateEvent;
            } else {
              return ev;
            }
          });
          console.log('updated an event!', event);
          this.getEventsForSvg(this.props.siteId, this.props.svg.id);
      }).catch((err: any) => {
        console.log('Error updating event!', err, input);
      });
    } else {
      const input = {
        createdAt: Date.now(),
        ...event,
      }
      API.graphql(graphqlOperation(
        createEvent,
        { input }
        // @ts-ignore
        )).then((event: any) => {
          const events = this.state.events.filter((ev: any) => ev.id || (!ev.id && ev.name !== event.data.createEvent.name));
          events.push(event.data.createEvent);
          console.log('made a event!', event);
          this.getEventsForSvg(this.props.siteId, this.props.svg.id);
          this.createEventLinksToHotPoints(event.data.createEvent.id);
      }).catch((err: any) => {
        console.log('Error creating new event!', err, input);
      });
    }
  };
  changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({hotPointPage: value});
  };
  getEventsForSvg = async (siteId: string, svgId: string) => {
    await API.graphql(
      graphqlOperation(
        getEventsForSVG,
        {
          id: this.props.siteId,
          eventFilter:  {svgId: {eq: this.props.svg.id}},
          eventLimit: 1000,
          svgLayerLimit: 1000,
          //nextEventToken: ,
          //nextSvgLayerToken: ,
        },
      )
      // @ts-ignore
    ).then((results: any) => {
      console.log('got event for svg', results)
      const events = results.data.getSite.events.items
      this.setState({events:[]});
      this.setState({events});
    }).catch((err: any) => {
      console.error('error getEventsForSvg', err)
    });
  }
  createEventLinksToHotPoints = (eventId: string) => {
      this.state.hotPoints.map(async (hp: any, index: number) => { //no need to await as this is fire&forget
        const hotPoint = hp.hotPoint;
        await API.graphql(
          graphqlOperation(
            createHotPointEventLink,
            {
              input: {
                hotPointId: hotPoint.id,
                eventId: eventId,
              }
            },
          )
          // @ts-ignore
        ).then((link: any) => {
          console.log('link created for event: ', link)
        }).catch((err: any) => {
          console.error('Error creating link:', err)
        });
      })
  }
  createHotPointLinksToEvents = () => {
    this.state.events.map((event: any, index: number) => {
      this.state.hotPoints.map(async (hp: any, index: number) => { //no need to await as this is fire&forget
        const hotPoint = hp.hotPoint;
        await API.graphql(
          graphqlOperation(
            createHotPointEventLink,
            {
              input: {
                hotPointId: hotPoint.id,
                eventId: event.id,
              }
            },
          )
          // @ts-ignore
        ).then((link: any) => {
          console.log('link created for event: ', link)
        }).catch((err: any) => {
          console.error('Error creating link:', err)
        });
      })
    });
  }
  saveInitialHotPoints = async (hps: any) => {
    const hotPointPromises = hps.map(async (currentHotPoint: any) => {
      const input = {
        createdAt: Date.now(),
        componentNumber: currentHotPoint.hotPoint.componentNumber,
        description: currentHotPoint.hotPoint.description,
        manufacturer: currentHotPoint.hotPoint.manufacturer,
        partNumber: currentHotPoint.hotPoint.partNumber,
        stockCode: currentHotPoint.hotPoint.stockCode,
        electricalAddress: currentHotPoint.hotPoint.electricalAddress,
        componentApplication: currentHotPoint.hotPoint.componentApplication,
        rlNumber: currentHotPoint.hotPoint.rlNumber,
        referenceTag: currentHotPoint.hotPoint.referenceTag,
        settings: currentHotPoint.hotPoint.settings,
      };
      return await API.graphql(
        graphqlOperation(
          createHotPoint,
          {input},
        )
        // @ts-ignore
      ).then((hotPoint: any) => {
        return {hotPoint: hotPoint.data.createHotPoint};
      }).catch((err: any) => {
        console.log('Error createHotPoint:', err)
      });
    })
    console.log('InitialHotPoints', hotPointPromises);
    const hotPoints = await Promise.all(hotPointPromises);
    console.log('resolvedHPs', hotPoints)
    this.setState({hotPoints});
    this.createHotPointLinksToEvents();
  }

  render() {
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container
        id="svgDisplay"
        className={styles.root}
        maxWidth={false}
      >
        <Typography
          id="svgDisplayText"
          variant="body1"
          className={styles.typography}
          style={{display: 'inline-block'}}
        >
          SVG: {`${this.props.svg.name}`}
        </Typography>
        <Button
        id="hotPointsButton"
        color="primary"
        className={styles.button}
        onClick={() => {this.setState({displayHotPoints: !this.state.displayHotPoints})}}>
           Hot Points
        </Button>
        <Button
          id="eventsButton"
          color="primary"
          className={styles.button}
          onClick={() => {
            this.getEventsForSvg(this.props.siteId, this.props.svg.id);
            this.setState({displayEvents: !this.state.displayEvents}); console.log("events", this.state.events)
          }}>
          Events
        </Button>
        <Button
          id="replaceSvgButton"
          color="secondary"
          className={styles.button}
          onClick={() => {console.log('to be implemented')}}>
          Replace SVG
        </Button>
        <Button
          id="deleteSvgButton"
          color="secondary"
          className={styles.button}
          onClick={this.deleteSvg}>
          Delete
        </Button>
        <Container maxWidth={false} disableGutters>
        {this.state.displayHotPoints
          && <Container maxWidth={false} disableGutters>
          {this.state.hotPoints.length == 0
            && <Container maxWidth={false} disableGutters>
              <TextField
                id="idLayerNameTextField"
                value={this.state.idLayerName}
                onChange={(event: any) => this.setState({idLayerName: event.target.value as string})}
              />
              <Button
              id="createHotPointsButton"
              variant="outlined"
              color="primary"
              className={styles.button}
              onClick={this.createHotPoints}
            >
              Generate Hot Points
            </Button>
            <Button
              id="openCSVDialog"
              variant="outlined"
              color="primary"
              className={styles.button}
              onClick={() => {
                this.setState({displayCsv: true});
              }}
            >
              Import from CSV
            </Button>
            <Dialog open={this.state.displayCsv} onClose={() => {this.setState({displayCsv: false})}} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Import HotPoints via CSV</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Paste the CSV in the box below.  Do not include headers.  sGenerating hot point data in this way will over-write existing hotpoint data!
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="csvTextField"
                  label="CSV"
                  type="text"
                  fullWidth
                  multiline
                  onChange={(event: any) => this.setState({rawCsv: event.target.value as string})}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({displayCsv: false})}
                  color="secondary"
                  className={styles.button}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    this.setState({displayCsv: false});
                    this.parseHotPointsFromCSV(this.state.rawCsv);
                    this.saveHotPoints();
                  }}
                  color="primary"
                  className={styles.button}
                >
                  Parse
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
          }
          {this.state.hotPoints.length > 0
            && <Container maxWidth={false} disableGutters>
                <Button
                  id="saveHotPointsButton"
                  variant="outlined"
                  color="primary"
                  className={styles.button}
                  onClick={this.saveHotPoints}
                >
                  Save HotPoints
                </Button>
                <Button
                  id="deleteHotPointsButton"
                  variant="outlined"
                  color="secondary"
                  className={styles.button}
                  onClick={this.deleteHotPoints}
                >
                  Delete HotPoints
                </Button>
                <Pagination count={Math.floor(this.state.hotPoints.length/10)+1} color="primary" onChange={(input, value) => this.changePage(input, value)}/>
                {this.state.hotPoints.length > 0 && this.state.hotPoints.map((hotPoint: any, index: number) => {
                  if(index >= (this.state.hotPointPage-1) * 10 && index < (this.state.hotPointPage-1) * 10 + 10) {
                    return (<HotPointListItem
                              saveHotPointsCallback={this.saveHotPoints}
                              key={index}
                              updateHotPoints={this.updateHotPoints}
                              hotPoint={hotPoint.hotPoint}/>);
                  }
                })}
              </Container>
          }
          </Container>
        }
        {this.state.displayEvents
          && <Container>
            {this.state.events.map((event: any, index: number) => {
            console.log("events", index, event)
            return (<EventDisplay
              key={index}
              index={index}
              updateEvents={this.updateEvents}
              deleteEvent={this.deleteEvent}
              saveEvent={this.saveEvent}
              event={event}
            />);
          })}
          <Button
            id="eventsButton"
            color="primary"
            className={styles.button}
            onClick={() => {
              const newEvent = {
                siteId: this.props.siteId,
                name: 'New Event',
                namesOfLayersToDisplay: [],
                svgId: this.props.svg.id,
              };
              this.saveEvent(newEvent);
            }}>
            Add Event
          </Button>
          </Container>
        }
        </Container>
      </Container>
    );
  }
}

export default withStyles(styles)(SVGDisplay);

export const getContractDataOnly = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      name
      company
      createdAt
      owner
      contractLead
      status
    }
  }
`;

export const getProjectDataOnly = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      contractId
      name
      company
      projectLead
      status
    }
  }
`;

export const getSiteWithCustomLimits = /* GraphQL */ `
  query GetSite(
    $id: ID!
    $eventLimit: Int
    $svgLimit: Int
    $svgLayerLimit: Int
    $hotPointLimit: Int
    $nextEventToken: String
    $nextHotPointToken: String
    $nextSVGToken: String
    $nextSVGLayerToken: String
  ) {
    getSite(id: $id) {
      id
      createdAt
      updatedAt
      projectId
      name
      usernames
      events (limit: $eventLimit, nextToken: $nextEventToken) {
        items {
          id
          createdAt
          updatedAt
          siteId
          name
          sequenceOfOperations
          svg {
            id
            siteId
            name
            createdAt
            updatedAt
            svgLayers (limit: $svgLimit, nextToken: $nextSVGToken) {
              items {
                id
                type
                createdAt
                updatedAt
                ownerCognitoIdentityId
                objectName
                s3ObjectId
              }
            }
          }
          namesOfLayersToDisplay
        }
        nextToken
      }
      associatedSVGs {
        items {
          id
          siteId
          name
          createdAt
          updatedAt
          svgLayers (limit: $svgLayerLimit, nextToken: $nextSVGLayerToken) {
            items {
              id
              parentId
              type
              createdAt
              updatedAt
              ownerCognitoIdentityId
              objectName
              s3ObjectId
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;

//there's no limit on images, so it will get 10
export const getHotPointsForSite = `
query GetAllHotPointsForSite(
  $id: ID!
  $hotPointFilter: ModelHotPointEventLinkFilterInput
  $eventFilter: ModelEventFilterInput
  $hotPointLimit: Int
  $eventLimit: Int
  $nextEventToken: String
  $nextHotPointToken: String
) {
    getSite(id: $id) {
        events(filter: $eventFilter, limit: $eventLimit, nextToken: $nextEventToken) {
            items {
                hotPoints(filter: $hotPointFilter, limit: $hotPointLimit, nextToken: $nextHotPointToken) {
                  items {
                    hotPoint {
                      id
                      createdAt
                      updatedAt
                      componentNumber
                      description
                      manufacturer
                      partNumber
                      stockCode
                      electricalAddress
                      componentApplication
                      rlNumber
                      rlItem {
                        id
                      }
                      images {
                        items {
                          id
                          type
                          createdAt
                          updatedAt
                          ownerCognitoIdentityId
                          objectName
                          s3ObjectId
                        }
                      }
                      referenceTag
                      settings
                    }
                  }
                }
            }
        }
    }
}
`;

export const getHotPointsForSvg = `
query GetAllHotPointsForSvg(
  $id: ID!
  $hotPointFilter: ModelHotPointEventLinkFilterInput
  $svgFilter: ModelSVGFilterInput
  $eventFilter: ModelEventFilterInput
  $hotPointLimit: Int
  $eventLimit: Int
  $svgLimit: Int
  $nextSvgToken: String
  $nextEventToken: String
  $nextHotPointToken: String
) {
    getSite(id: $id) {
        associatedSvgs(filter: $svgFilter, limit: $svgLimit, nextToken: $nextSvgToken) {
            items {
                events (filter: $eventFilter, limit: $eventLimit, nextToken: $nextEventToken){
                  items {
                    hotPoints(filter: $hotPointFilter, limit: $hotPointLimit, nextToken: $nextHotPointToken) {
                      items {
                        hotPoint {
                          id
                          createdAt
                          updatedAt
                          componentNumber
                          description
                          manufacturer
                          partNumber
                          stockCode
                          electricalAddress
                          componentApplication
                          rlNumber
                          rlItem {
                            id
                          }
                          images {
                            items {
                              id
                              type
                              createdAt
                              updatedAt
                              ownerCognitoIdentityId
                              objectName
                              s3ObjectId
                            }
                          }
                          referenceTag
                          settings
                        }
                      }
                    }
                  }
                }
            }
        }
    }
}
`;

export const getEventsForSite = `
query GetAllHotPointsForSite(
  $id: ID!
  $eventFilter: ModelEventFilterInput
  $svgLayerLimit: Int
  $eventLimit: Int
  $nextEventToken: String
  $nextSVGLayerToken: String
) {
    getSite(id: $id) {
        events (filter: $eventFilter, limit: $eventLimit, nextToken: $nextEventToken) {
            items {
                id
                createdAt
                updatedAt
                name
                sequenceOfOperations
                svg {
                  id
                  name
                  createdAt
                  updatedAt
                  svgLayers (limit: $svgLayerLimit, nextToken: $nextSVGLayerToken) {
                    items {
                      id
                      type
                      createdAt
                      updatedAt
                      ownerCognitoIdentityId
                      objectName
                      s3ObjectId
                    }
                    nextToken
                  }
                }
                namesOfLayersToDisplay
            }
            nextToken
        }
    }
}
`

export const getHotPointsFromSVG = `
query GetSVG(
  $id: ID!
  $hotPointLimit: Int
  $nextHotPointToken: String
) {
  getSVG(id: $id) {
    events (limit: 1) {
      items {
        hotPoints (limit: $hotPointLimit, nextToken: $nextHotPointToken){
          items {
            hotPoint {
              id
              createdAt
              updatedAt
              componentNumber
              description
              manufacturer
              partNumber
              stockCode
              electricalAddress
              componentApplication
              rlNumber
              settings
              referenceTag
            }
          }
        }
      }
    }
  }
}
`;

export const getEventsForSVG = `
query GetSite(
  $id: ID!
  $eventLimit: Int
  $svgLayerLimit: Int
  $nextEventToken: String
  $nextSVGLayerToken: String
  $eventFilter: ModelEventFilterInput
) {
  getSite(id: $id) {
    events (filter: $eventFilter, limit: $eventLimit, nextToken: $nextEventToken) {
      items {
        id
        createdAt
        updatedAt
        siteId
        svgId
        name
        sequenceOfOperations
        svg {
          id
          siteId
          name
          createdAt
          updatedAt
          svgLayers (limit: $svgLayerLimit, nextToken: $nextSVGLayerToken) {
            items {
              id
              parentId
              type
              createdAt
              updatedAt
              ownerCognitoIdentityId
              objectName
              s3ObjectId
            }
            nextToken
          }
        }
        namesOfLayersToDisplay
      }
      nextToken
    }
  }
}
`;

export const getSiteDataOnly = `
query GetSite($id: ID!) {
  getSite(id: $id) {
    id
    createdAt
    updatedAt
    projectId
    name
    usernames

    events {
      items {
        id
        createdAt
        updatedAt
        siteId
        svgId
        name
        namesOfLayersToDisplay
      }
      nextToken
    }
    associatedSvgs {
      items {
        id
        siteId
        name
        createdAt
        updatedAt
        svgLayers {
          items {
            id
            parentId
            type
            createdAt
            updatedAt
            ownerCognitoIdentityId
            objectName
            s3ObjectId
          }
          nextToken
        }
      }
      nextToken
    }
  }
}
`;

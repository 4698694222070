import React from 'react';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { createSite } from '../graphql/mutations';

interface newSiteProps {
  setActiveScreen: any,
  updateSidebar: any,
  projectId: string,
}
interface newSiteState {
  name: string,
  submitted: boolean,
  credentials: any,
}

export default class NewSite extends React.Component <newSiteProps, newSiteState> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: `New Site`,
      submitted: false,
      credentials: {},
    };
  }

  componentDidMount () {
    Auth.currentCredentials().then((credentials) => {
      this.setState({credentials})
    }).catch((err) => {
      console.log('Error fetching credentials!', err);
    });
  }

  handleChange(event: any, value: string) {
      this.setState({
			...this.state,
    		[value]: event.target.value
      });
  }

  createSite = async () => {
    // console.log('formProps', this.props);
    if(!this.state.submitted) {
      this.setState({submitted: true});
      const site =  await API.graphql(graphqlOperation(
        createSite,
        {
          input: {
            name: this.state.name,
            projectId: this.props.projectId,
            usernames: [],
            createdAt: Date.now(),
          }}
          // @ts-ignore
        )).then((site: any) => {
          return site
      }).catch((err: any) => {
        console.log('Error creating new site!', err);
        this.setState({submitted: false});
        return err;
      });
      if(site && site.data && !site.errors) {
        return site.data.createSite;
      }
      else {
        console.log('Error in createSite!')
      }
    }
  }

  render() {
    return (
      <Container>
        <div>
          <h1 id="newSiteHeader">New Site</h1>
          <form id="siteForm" noValidate onSubmit={(event) => {event.preventDefault();}}>
            <h2>New Site</h2>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="name">Site Name</InputLabel>
              <Input id="name" name="name" type="text" value={this.state.name} onChange={(event) => this.handleChange(event, 'name')}/>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              id="submitButton"
              onClick={ async () => {
                const site = await this.createSite();
                console.log('site from dynamo',site);
                try{
                  this.props.setActiveScreen("siteEditor", {site});
                  console.log(site);
                } catch (err) {
                  console.log('Error in onClick', err);
                }
              }} >
            Create Site
          </Button>
          </form>
        </div>
      </Container>
    );
  };
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper'

import { API, graphqlOperation } from 'aws-amplify';
import { listRLItems } from '../graphql/queries';
import RlItem from '../components/rlItem';


interface RLContainerProps {
  setActiveScreen: any,
  updateSidebar: any,
  theme: any,
  classes: any,

}
interface RLContainerState {
  rlItems: any,
}

export default class RLContainer extends React.Component <RLContainerProps, RLContainerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      rlItems: [],
    };
  }

  async componentDidMount () {
    // @ts-ignore
    await API.graphql(graphqlOperation(listRLItems, {limit: 10000})).then((rlItems: any) => {
      console.log('got some items', rlItems);
      this.setState({rlItems: rlItems.data.listRLItems.items}); //the one time RL is not camelcased
    }).catch((err: any) => {
      console.error('Error listing rlItems', err);
    });
  }

  newRlItem = () => {
    this.props.setActiveScreen('newRlItemForm', {})
  }
  deleteRlItem = (deletedRlItem: any) => {
    const rlItems = this.state.rlItems.filter((rlItem: any) => {
      return rlItem.id !== deletedRlItem.id
    });
    this.setState({rlItems});
  }

  render() {
    return <Container>
      <Typography>
        RL Items
      </Typography>
      <Paper>
        {this.state.rlItems.map((rlItem: any, index: number) => {
          return <RlItem
            key={index}
            rlItem={rlItem}
            updateSidebar={this.props.updateSidebar}
            setActiveScreen={this.props.setActiveScreen}
            deleteRlItem={this.deleteRlItem}
          />
        })}
      </Paper>
      <Button
      variant="contained"
      color="primary"
      id="editRlItemButton"
      onClick={() => this.newRlItem()}
      >
        <Typography>
          New RL Item
        </Typography>
      </Button>
    </Container>
  }
}

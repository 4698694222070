import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { createS3Item, deleteS3Item } from '../graphql/mutations';
import { deleteS3ItemAndImages } from '../graphql/graphqlFunctions';
import xml2js from 'xml2js';
//import { updateSite } from '../graphql/mutations';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
  componentApplication: {
      width: '100%'
  },
};

interface HotPointListProps {
  classes: any,
  updateHotPoints: any,
  saveHotPointsCallback: any,
  hotPoint: any
}
interface HotPointListState {
  isOpen: boolean,
  createdAt: string,
  updatedAt: string,
  rlNumber: string,
  description: string,
  manufacturer: string,
  partNumber: string,
  stockCode: string,
  electricalAddress: string,
  componentApplication: string,
  referenceTag: string,
  settings: string,
  images: any,
}

class HotPointList extends React.Component <HotPointListProps, HotPointListState> {

  constructor(props: any) {
    super(props);
    console.log('hplistitem props', this.props.hotPoint);
    const {
      createdAt,
      updatedAt,
      rlNumber,
      description,
      manufacturer,
      partNumber,
      stockCode,
      electricalAddress,
      componentApplication,
      referenceTag,
      settings,
      images,
    } = this.props.hotPoint;
    this.state = {
      isOpen: false,
      createdAt: createdAt ? createdAt : undefined,
      updatedAt: updatedAt ? updatedAt : undefined,
      rlNumber: rlNumber ? rlNumber : '',
      description: description ? description : '',
      manufacturer: manufacturer ? manufacturer : '',
      partNumber: partNumber ? partNumber : '',
      stockCode: stockCode ? stockCode : '',
      electricalAddress: electricalAddress ? electricalAddress : '',
      componentApplication: componentApplication ? componentApplication : '',
      referenceTag: referenceTag ? referenceTag : '',
      settings: settings ? settings : '',
      images: images ? images : {items: []},
    };
  }

  componentDidMount() {
    console.log('inside hplineitem', this.state, this.props)
  }

  updateInfo = (update: string, field: string) => {
    const newState: any = this.state;
    newState.id = this.props.hotPoint.id;
    newState.updatedAt = this.props.hotPoint.updatedAt;
    newState.createdAt = this.props.hotPoint.createdAt;
    newState[field] = update;
    console.log('inHotPointLineItems', newState);
    this.setState(newState);
  }
  updateStateCallback = (deletedS3Item: any) => {
    const items = this.state.images.items.filter((s3Item: any) => deletedS3Item.id !== s3Item.id );
    console.log('state after delete', items)
    this.setState({images: {items}});
    console.log('state after delete', this.state)
  }
  uploadImageToS3 = (event: any) => {
    const image = event.target.files[0];
    Auth.currentCredentials().then(async user => {
      Storage.put(`${image.name}-${Date.now()}`, image, {level: 'protected', contentType: 'image'}).then(async (data) =>{
        await API.graphql(graphqlOperation(
          createS3Item,
          {
            input: {
              parentId: this.props.hotPoint.id,
              type: 'svg',
              createdAt: Date.now(),
              ownerCognitoIdentityId: user.identityId,
              objectName: image.name,
              //@ts-ignore
              s3ObjectId: data.key,
            }
          }
          // @ts-ignore
        )).then((results: any) => {
            console.log('new S3 item', results.data);
            const images = this.state.images;
            images.items.push(results.data.createS3Item)
            this.setState({images});
        }).catch((err: any) => {
          console.log('Error creating s3 item!', err);
        });
        // @ts-ignore
      }).catch(err => {
        console.error('error in upload of image: ', err);
      });
    }).catch((err) => {
      console.error('Error uploading ', err)
    });
  }

  render(){
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Paper className={styles.root}>
        <Grid container spacing={1} onBlur={() => {this.props.updateHotPoints({ hotPoint: {...this.state, id: this.props.hotPoint.id, componentNumber: this.props.hotPoint.componentNumber}});}}>
          <Grid container item xs={1}>
            <Typography id="componentNumberTypography" variant='h4'>
              {this.props.hotPoint.componentNumber}
            </Typography>
          </Grid>
          <Grid container spacing={1} xs={10}>
            <Grid item container xs>
              <TextField

                id="rlNumberTextField"
                placeholder='RL Number'
                label={'RL Number'}
                value={this.state.rlNumber}
                onChange={(event) => this.updateInfo(event.target.value as string, 'rlNumber')}
              />
            </Grid>
            <Grid item container xs={2}>
              <TextField

                id="descriptionTextField"
                placeholder='Description'
                label={'Description'}
                multiline
                value={this.state.description}
                onChange={(event) => this.updateInfo(event.target.value as string, 'description')}
              />
            </Grid>
            <Grid item container xs>
              <TextField

                id="manufacturerTextField"
                placeholder='Manufacturer'
                label={'Manufacturer'}
                value={this.state.manufacturer}
                onChange={(event) => this.updateInfo(event.target.value as string, 'manufacturer')}
              />
            </Grid>
            <Grid item container xs>
              <TextField

                id="partNumberTextField"
                placeholder='Part #'
                label={'Part #'}
                value={this.state.partNumber}
                onChange={(event) => this.updateInfo(event.target.value as string, 'partNumber')}
              />
            </Grid>
            <Grid item container xs>
              <TextField

                id="stockCodeTextField"
                placeholder='Stock Code'
                label={'Stock Code'}
                value={this.state.stockCode}
                onChange={(event) => this.updateInfo(event.target.value as string, 'stockCode')}
              />
            </Grid>
            <Grid item container xs>
              <TextField

                id="electricalAddressTextField"
                placeholder='Electrical Address'
                label={'Electrical Address'}
                value={this.state.electricalAddress}
                onChange={(event) => this.updateInfo(event.target.value as string, 'electricalAddress')}
              />
            </Grid>
            <Grid item container xs>
              <TextField

                id="referenceTagTextField"
                placeholder='Reference Tag'
                label={'Reference Tag'}
                value={this.state.referenceTag}
                onChange={(event) => this.updateInfo(event.target.value as string, 'referenceTag')}
              />
            </Grid>
            <Grid item container xs>
              <TextField

                id="settings"
                placeholder='Settings'
                label={'Settings'}
                value={this.state.settings}
                onChange={(event) => this.updateInfo(event.target.value as string, 'settings')}
              />
            </Grid>
            <Grid item container xs={12} >
              <TextField

                className={styles.componentApplication}
                multiline
                id="componentApplicationTextField"
                placeholder='Component Application'
                label={'Component Application'}
                value={this.state.componentApplication}
                onChange={(event) => this.updateInfo(event.target.value as string, 'componentApplication')}
              />
            </Grid>
          </Grid>
          <Grid item container xs={1}>
            <Button
              id="imageButton"
              variant="contained"
              component="label"
              color={'primary'}
            >
              Upload Image
              <input
                type="file"
                accept="image/*"
                multiple
                style={{ display: "none" }}
                onChange={(evt) => {this.uploadImageToS3(evt)}}
              />
            </Button>
          {this.state.images && this.state.images.items.map((s3Item: any, index: number) => {
              return (
                <Grid item container xs key={index}>
                  <Typography>
                    {s3Item.objectName}
                  </Typography>
                  <Button
                    id="imageButton"
                    variant="contained"
                    component="label"
                    color={'secondary'}
                    onClick={() => {deleteS3ItemAndImages(s3Item.id, this.updateStateCallback)}}
                  >
                    Remove Image
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(HotPointList);

import React from 'react';
import Homepage from './homepage';
import { withAuthenticator } from '@aws-amplify/ui-react';
import DisplayAndDrawerWrapper from './wrappers/displayAndDrawerWrapper';

//TODO: look into chrome svg scaling
// handle null hotpoints on siteDetailedDisplay
// fix identityID on getImage for hotpoints
// fix adding s3 items on editRLItem
// add RL supplement feature attached to HP
// fix hotpoint dsplay modulus
// replace svg button
export default withAuthenticator(DisplayAndDrawerWrapper);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
};

interface ContractDisplayProps {
  key: number,
  contract: any,
  setActiveScreen: any,
  updateSidebar: any,
}
interface ContractDisplayState {}

class ContractDisplay extends React.Component <ContractDisplayProps, ContractDisplayState> {
  constructor(props: any) {
    super(props);
  }



  render() {
    let backgroundColor;
    switch(this.props.contract.status) {
      case 'active': backgroundColor = 'green'; break;
      case 'complete': backgroundColor = 'gray'; break;
      default: backgroundColor = 'yellow';
    }
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container id="contract">
        <Paper
          className={styles.root}
        >
          <Typography
            className={styles.typography}
            variant="body1"
            style={{display: 'inline-block'}}
          >
            {`${this.props.contract.name} with ${this.props.contract.company}`}
          </Typography>
          <br/>
          <Typography
            className={styles.typography}
            variant="body1"
            style={{display: 'inline-block'}}
          >
            {`POC: ${this.props.contract.contractLead} Status: ${this.props.contract.status}`}
          </Typography>
          <Button
            className={styles.button}
            id="editButton"
            variant="contained"
            color="primary"
            onClick={() => {console.log('editOnclick', this.props); this.props.setActiveScreen('contractDetailedDisplay', {contract: this.props.contract})}}>
            Details
          </Button>
        </Paper>
      </Container>
    );
  };
}

export default withStyles(styles)(ContractDisplay);

const VALID_TAG_REGEX = /^[a-zA-Z][a-zA-Z:_.\-\d]*$/;

const nameCache: any = {};

export default function isValidTagOrAttributeName(tagName: string) {
  if (!nameCache[tagName]) {
    nameCache[tagName] = VALID_TAG_REGEX.test(tagName);
  }
  return nameCache[tagName];
}

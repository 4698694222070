import { Storage, Auth, API, graphqlOperation } from 'aws-amplify'
import { createS3Item, createSVG, deleteSVG, deleteS3Item, deleteEvent, deleteHotPointEventLink } from './mutations';
import {
  getHotPointsFromSVG,
} from './customQueries'
import {
  deleteEventAndReturnLinkIdsToDelete,
  deleteHotPointEventLinkDBCleanup,
  deleteHotPointAndReturnLinkIdsToDeleteAndImages,
  deleteSVGWithCustomLimits,
} from './customMutations';

export const deleteSVGFromSite = async (svgId: string, callback?: Function) => {
  // deletes the svg, hotpoints, and events associated with the svg
  console.log('svgId for delete',svgId)
  const hotPointPromises: Array<Promise<Object>> = [];
  const hotPointsToDelete = await API.graphql(graphqlOperation(
    getHotPointsFromSVG,
    {
      id: svgId,
      hotPointLimit: 1000,
    }
    // @ts-ignore
  )).then((results) => {
    console.log('getHotPointsFromSvg results',results, svgId)
    if(results.data.getSVG
      && results.data.getSVG.events
      && results.data.getSVG.events.items
      && results.data.getSVG.events.items[0].hotPoints
      && results.data.getSVG.events.items[0].hotPoints.items
    ) {
      return results.data.getSVG.events.items[0].hotPoints.items;
    }
    else {
      return [];
    }
  }).catch((err: any) => {
    console.error("error getHotPointsFromSvg", err);
    return [];
  });
  hotPointsToDelete.map((hotPoint: any) => {
    hotPointsToDelete.push(new Promise((resolve, reject) => {
      deleteHotPointAndImagesAndLinks(hotPoint.hotPoint.id).then((results: any) => {
        resolve(results);
      }).catch((err: any) => {
        reject(err);
      });
    }));
  });
  await Promise.all(hotPointPromises).then((results: any) => {
    console.log('deleted a bunch of hotpoints',results)
  }).catch((err: any) => {
    console.error('error in hotPointPromises', err);
  });
  return await API.graphql(graphqlOperation(
    deleteSVGWithCustomLimits,
    {
      input: {
        id: svgId,
      },
      eventLimit: 1000,
      svgLayerLimit: 1000,
    }
    // @ts-ignore
  )).then(async (results: any) => {
      const svgToDelete = results.data.deleteSVG;
      console.log('deleted SVG',svgToDelete)
      const promises: Array<Promise<Object>> = [];
      const eventPromises: Array<Promise<Object>> = [];
      const svgLayerPromises: Array<Promise<Object>> = [];

      svgToDelete.svgLayers.items.map((s3Item: any) => {
        promises.push(new Promise((resolve, reject) => {
          deleteS3ItemAndImages(s3Item.id).then((results: Object) => {
            resolve(results)
          }).catch((err: any) => {
            reject(err);
          });
        }));
      });
      svgToDelete.events.items.map((event: any) => {
        promises.push(new Promise((resolve, reject) => {
          deleteEventAndLinks(event.id).then((results: any) => {
            resolve(results);
          }).catch((err: Object) => {
            reject(err);
          });
        }));
      });

      await Promise.all(promises).then((results: Object) => {
        console.log('all good after delete', results)
        if(callback) {
          callback();
        }
      }).catch(async (err: any) => {
        //readd svg since s3 deletes Failed
        console.error('error deleitng svg', err)
        await API.graphql(graphqlOperation(
          createSVG,
          {
            input: {
              id: svgToDelete.id,
              siteId: svgToDelete.siteId,
              name: svgToDelete.name,
              createdAt: svgToDelete.createdAt,
              updatedAt: svgToDelete.updatedAt,
            }
          }
          // @ts-ignore
        )).then((results: any) => {
          console.log('readded svg after s3Items delete failed', results)
        }).catch((err: any) => {
          console.error('failed to readd svg after deletes failed!', err)
        });
        console.error('boo')
        return err;
      });
  }).catch((err: any) => {
    console.log('Error updating site!', err);
  });
};
export const deleteS3ItemAndImages = (s3ItemId: string, callback?: Function) => {
  // deletes the s3Item and the image from s3 storage.
  // if s3 delete fails, it restores the s3 item
  return API.graphql(graphqlOperation(
    deleteS3Item,
    {
      input: {
        id: s3ItemId,
      }
    }
    // @ts-ignore
  )).then(async (results: any) => {
    const deletedS3Item = results.data.deleteS3Item;
    await Storage.remove(deletedS3Item.s3ObjectId, {level: 'protected', identityId: deletedS3Item.ownerCognitoIdentityId}).then((results) => {
      console.log('deleted S3 item & data!', results);
      if (callback) {
        callback(deletedS3Item);
      }
      return results;
    }).catch(async (err: any) => {
      console.error('failed to delete s3 object, restoring s3Item...',err);
      return await API.graphql(graphqlOperation(
        createS3Item,
        {
          input: {
            ...deletedS3Item,
          }
        }
        // @ts-ignore
      )).then((results: any) => {
        console.log('S3 item restored. Attempt deleting again later', results);
        return results;
      }).catch((err: any) => {
        console.error('Error restoring S3 Item!  Beware of orphaned s3 object!',err);
        return err;
      })
    })
  }).catch((err: any) => {
    console.error('failed to delete s3 item.  s3 objects have not been modified', err);
    return err;
  });
};
export const deleteEventAndLinks = async (eventId: any) => {
  // deletes the event and all linkages for the lookup table
  await API.graphql(graphqlOperation(
    deleteEventAndReturnLinkIdsToDelete,
    {
      input: {
        id: eventId,
      },
      hotPointLimit: 1000,
      imageLimit: 1000,
    },
    // @ts-ignore
  )).then(async (results: any) => {
    console.log('delete event',results.data)
    results.data.deleteEvent.hotPoints.items.map(async (hotPointLink: any) => {
      console.log('deleitig hotpointlink',hotPointLink)
      await API.graphql(graphqlOperation(
        deleteHotPointEventLinkDBCleanup,
        {
          input: {id: hotPointLink.id},
          hotPointLimit: 1000,
          imageLimit: 1000,
       } //more weird shapes courtesy of aws
        // @ts-ignore
      )).then((data: any) => {
        console.log('Link successfully deleted', data);
      }).catch((err: any) => {
        console.error('Error deleting link', err);
      });
    })
  }).catch((err: any) => {
    console.log('Error deleting event!', err);
  });

};
export const deleteHotPointAndImagesAndLinks = async (hotPointId: any) => {
  await API.graphql(graphqlOperation(
    deleteHotPointAndReturnLinkIdsToDeleteAndImages,
    {
      input: {
        id: hotPointId,
      },
      eventLimit: 1000,
      imageLimit: 1000,
    },
    // @ts-ignore
  )).then(async (results: any) => {
    const deletedHotPoint = results.data.deleteHotPoint;
    console.log('deleteeing hotpoints',results.data);
    deletedHotPoint.events.items.map(async (eventLink: any) => {
      await API.graphql(graphqlOperation(
        deleteHotPointEventLinkDBCleanup,
        { input: {id: eventLink.id} } //more weird shapes courtesy of aws
        // @ts-ignore
      )).then((data: any) => {
        console.log('Link successfully deleted', data);
        if(deletedHotPoint.images) {
          deletedHotPoint.images.items.map((s3Item: any) => {
            const promises: Array<Promise<any>> = [];
            promises.push(new Promise((resolve, reject) => {
              deleteS3ItemAndImages(s3Item.id).then((results: any) => {
                resolve(results);
              }).catch((err: any) => {
                reject(err);
              });
            }));
          });
        }
      }).catch((err: any) => {
        console.error('Error deleting link', err);
      });
    })
  }).catch((err: any) => {
    console.log('Error deleting hotPoint!', err);
  });
};

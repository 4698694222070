import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SiteDisplayContainer from './siteDisplayContainer';

interface ProjectDetailedDisplayProps {
  project: any,
  setActiveScreen: any,
  updateSidebar: any,
}
interface ProjectDetailedDisplayState {
  userInfo: any,
}

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

export default class ProjectDetailedDisplay extends React.Component <ProjectDetailedDisplayProps, ProjectDetailedDisplayState> {
  constructor(props: any) {
    super(props);
    this.state = {
      userInfo: {}
    };
    console.log('projectDetailedDisplayConstructor', this.props.project)
  }

  componentDidMount() {
    // let username;
    // Auth.currentAuthenticatedUser().then((userInfo) => {
    //   username = userInfo.username
    // }).catch((err) => {
    //   console.log('Error fetching currentAuthenticatedUser!', err);
    // });
    //   API.graphql(
    //     graphqlOperation(
    //       listProjects,
    //       {contractId: {eq: this.props.contract.id}},
    //     )
    //   ).then((contracts: any) => {
    //     console.log(contracts);
    //     this.state = {...this.state, userInfo: {...this.state.userInfo, contracts: contracts.data.listContracts.items}};
    //     console.log(this.state);
    //   }).catch((err: any) => {
    //     console.log('Error getContract:', err);
    //   });
  }

  render() {
    return (
      <Container id="project">
        <Typography id="projectDisplayHeader">
          {`${this.props.project.name} with ${this.props.project.company}\nCompany POC: ${this.props.project.projectLead} Status: ${this.props.project.status}`}
        </Typography>
        <Typography>
          Projects
        </Typography>
        <SiteDisplayContainer
          id={'siteDisplayContainer'}
          project={this.props.project}
          company={this.props.project.company}
          sites={this.props.project.sites}
          setActiveScreen={this.props.setActiveScreen}
          updateSidebar={this.props.updateSidebar}
        />
      </Container>
    );
  };
}

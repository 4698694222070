export const deleteEventAndReturnLinkIdsToDelete = `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
  $hotPointLimit: Int
  $imageLimit: Int
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    siteId
    name
    hotPoints(limit: $hotPointLimit) {
      items {
        id
        hotPoint {
          images (limit: $imageLimit) {
            items {
              id
              ownerCognitoIdentityId
            }
          }
        }
      }
    }
  }
}
`;

export const deleteHotPointAndReturnLinkIdsToDeleteAndImages = `mutation DeleteEvent(
  $input: DeleteHotPointInput!
  $condition: ModelHotPointConditionInput
  $eventLimit: Int
  $imageLimit: Int
) {
  deleteHotPoint(input: $input, condition: $condition) {
    id
    images (limit: $imageLimit) {
      items {
        id
      }
    }
    events(limit: $eventLimit) {
      items {
        id
      }
    }
  }
}
`;

export const deleteHotPointEventLinkDBCleanup = /* GraphQL */ `
  mutation DeleteHotPointEventLink(
    $input: DeleteHotPointEventLinkInput!
    $condition: ModelHotPointEventLinkConditionInput
  ) {
    deleteHotPointEventLink(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteSVGWithCustomLimits = /* GraphQL */ `
  mutation DeleteSVG(
    $input: DeleteSVGInput!
    $condition: ModelSVGConditionInput
    $eventLimit: Int
    $svgLayerLimit: Int
    $nextEventToken: String
    $nextSVGLayerToken: String
  ) {
    deleteSVG(input: $input, condition: $condition) {
      id
      siteId
      name
      createdAt
      updatedAt
      events (limit: $eventLimit, nextToken: $nextEventToken){
        items {
          id
        }
        nextToken
      }
      svgLayers (limit: $svgLayerLimit, nextToken: $nextSVGLayerToken){
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
        }
        nextToken
      }
    }
  }
`;

import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LaunchIcon from '@material-ui/icons/Launch';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import JWTDecode from 'jwt-decode';
import { Auth } from 'aws-amplify';

interface hotPointDisplayPopoutProps {
  userRole: string,
  classes: any,
  theme: any,
  handleDrawerClose: any,
  setActiveScreen: any,
  open: boolean,
}
interface hotPointDisplayPopoutState {
  userRole: Array<string>,
}

export default class HotPointList extends React.Component <hotPointDisplayPopoutProps, hotPointDisplayPopoutState> {
  constructor(props: any) {
    super(props);
    this.state = {
      userRole: [],
    }
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((currentUser) => {
      currentUser.getSession((err: any, session: any) => {
        if(err) {
          console.error('error getting session', err);
          return;
        }
        const sessionIdInfo = JWTDecode(session.getIdToken().jwtToken);
        // @ts-ignore
        this.setState({userRole: sessionIdInfo['cognito:groups']});
      });
    }).catch((err) => {
      console.error('Error retrieving currentAuthenticatedUser', err);
    });
  }

  getIcon = (id: string) => {
    return <LaunchIcon />
  }

  render() {
    const drawerItems: any = [];
    return <Drawer
      className={this.props.classes.drawer}
      variant="persistent"
      anchor="right"
      open={this.props.open}
      classes={{
        paper: this.props.classes.drawerPaper,
      }}
    >
      <div className={this.props.classes.drawerHeader}>
        <IconButton onClick={this.props.handleDrawerClose}>
          {this.props.theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          id={'homeButton'}
          button
          key={'home'}
          onClick={() => {
            if(this.state.userRole.includes('PCMAdmin')) {
              this.props.setActiveScreen('combinedDisplay', null)
            } else if(this.state.userRole.includes('users')) {
              this.props.setActiveScreen('userSiteView', null)
            }
          }}
        >
          <ListItemIcon><LaunchIcon /></ListItemIcon>
          <ListItemText id={'homeButtonText'} primary={'Home'} />
        </ListItem>
        {this.state.userRole.includes('PCMAdmin') &&
          <ListItem
            id={'rlEditButton'}
            button
            key={'rlEdit'}
            onClick={() => {this.props.setActiveScreen('rlEditor', null)}}
          >
            <ListItemIcon><PermMediaIcon /></ListItemIcon>
            <ListItemText id={'rlEditButtonText'} primary={'RL Configuration'} />
          </ListItem>
        }
        <Divider />
        <ListItem
          id='logoutItem'
          button
          key='logoutItem'
          onClick={async () => {
            try {
              await Auth.signOut();
              window.location.reload(true);
            } catch (error) {
                console.log('error signing out: ', error);
            }
          }}
        >
          <ListItemIcon><MeetingRoomIcon /></ListItemIcon>
          <ListItemText id={'LogoutText'} primary={'Logout'} />
        </ListItem>
        {drawerItems.map((drawerItem: any, index: number) =>
          {
            return (
              <ListItem id={drawerItem.buttonText.replace(/ /g, '')+'Button'} onClick={drawerItem.onClick} button disabled={drawerItem.disabled} key={drawerItem.buttonText}>
                <ListItemIcon>
                  {this.getIcon(drawerItem.icon)}
                </ListItemIcon>
                <ListItemText id={drawerItem.buttonText.replace(/ /g, '')} primary={drawerItem.buttonText} />
              </ListItem>
            )
          }
          // (
          // <ListItem  id={`${mainIds[index]}Button`} onClick={() => this.sideBarOnClick(mainIds[index])} button key={text}>
          //   <ListItemIcon><LaunchIcon /></ListItemIcon>
          //   <ListItemText id={mainIds[index]} primary={text} />
          // </ListItem>
          // )
      )}
      </List>
      <Divider />
    </Drawer>

  }
}

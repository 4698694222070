import React from 'react';
//import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import JWTDecode from 'jwt-decode';

import VideoPlayer from './videoPlayer';

import { deleteS3Item } from '../graphql/mutations';

const styles = {
  root: {
    padding: '10px', //em = dynamic units?
    margin: '10px',
  },
  container: {
    padding: '10px', //em = dynamic units?
    margin: '10px',
    backgroundColor: 'rgb(240, 240, 240)',
  },
  button: {
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  }
};

interface S3ItemProps {
  id: string,
  ownerCognitoIdentityId: string,
  objectName: string,
  s3ObjectId: string,
  deleteS3Item: any,
}

interface S3ItemState {
  id: string,
  objectName: string,
  downloadUrl: string,
  userRole: Array<string>,
  modalOpen: boolean,
  modalType: string
  viewText: string,
}

class S3Item extends React.Component <S3ItemProps, S3ItemState> {
  constructor(props: any) {
    super(props);
    this.state = {
        id: this.props.id ? this.props.id : '',
        objectName: this.props.objectName ? this.props.objectName : '',
        downloadUrl: '',
        userRole: [],
        modalOpen: false,
        modalType: '',
        viewText: '',
    }
  }

  async componentDidMount () {
    console.log('ids:', this.props.s3ObjectId, this.props.ownerCognitoIdentityId)
    await this.getDownloadUrl(this.props.s3ObjectId, this.props.ownerCognitoIdentityId);
    Auth.currentAuthenticatedUser().then((currentUser) => {
      currentUser.getSession((err: any, session: any) => {
        if(err) {
          console.error('error getting session', err);
          return;
        }
        const sessionIdInfo = JWTDecode(session.getIdToken().jwtToken);
        // @ts-ignore
        this.setState({userRole: sessionIdInfo['cognito:groups']});
      });
    }).catch((err) => {
      console.error('Error retrieving currentAuthenticatedUser', err);
    })
  }

  setModalOpen = (modalOpen: boolean, modalType: string) => {
    if(modalType === 'text') {
      this.downloadS3Object(this.props.s3ObjectId, this.props.ownerCognitoIdentityId).then(viewText => {
        this.setState({modalOpen, modalType, viewText});
      });
    }
    else {
      this.setState({modalOpen, modalType});
    }
  }

  getDownloadUrl = async (s3ItemId: string, identityId: string) => {
    await Auth.currentCredentials().then(async user => {
      await Storage.get(s3ItemId, {level: 'protected', identityId}).then(downloadUrl =>{
        // @ts-ignore
        this.setState({downloadUrl})
      }).catch(err => {
        console.error('error fetching URL: ', err);
      });
    }).catch((err) => {
      console.error('Error getting current user for download URL', err)
    });
  }

  downloadS3Object = async (s3ItemId: string, identityId: string) => {
    return await Auth.currentCredentials().then(async user => {
      return await Storage.get(s3ItemId, {level: 'protected', identityId, download: true}).then(async data =>{
        // @ts-ignore
        return await data.Body.text().then(text => {
          console.log('text', text)
          return text});
      }).catch(err => {
        console.error('error downloading text file: ', err);
      });
    }).catch((err) => {
      console.error('Error getting current user for text download', err)
    });
  }

  generateVideoPlayer = (s3ObjectUrl: string) => {
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      sources: [{
        src: s3ObjectUrl,
        type: 'video/mp4'
      }]
    }

    return <VideoPlayer { ...videoJsOptions } />
  }
  generateTextView = () => {
    //@ts-ignore
    const styles = this.props.classes;
    return(
      <Container maxWidth="lg" disableGutters>
        <Box className={styles.container}>
          <Typography>{this.state.viewText}</Typography>
        </Box>
      </Container>);
  }

  render(){
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Paper className={styles.root}>
        <Modal
          className={styles.modal}
          open={this.state.modalOpen}
          onClose={() => {
            this.setModalOpen(false, '')
          }}
        >
          <Container>
            {this.state.modalType === 'video' && this.generateVideoPlayer(this.state.downloadUrl)}
            {this.state.modalType === 'text' && this.generateTextView()}
          </Container>
        </Modal>
        <Typography variant='h6' className={styles.typography}>
          {this.props.objectName}
        </Typography>

        {this.props.objectName.slice(-4) === '.mp4' &&
          <Button
            id="watchButton"
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={() => {this.setModalOpen(true, 'video')}}
          >
            <Typography>
              Watch
            </Typography>
          </Button>
        }

        {this.props.objectName.slice(-4) === '.txt' &&
          <Button
            id="viewTextButton"
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={() => {this.setModalOpen(true, 'text')}}
          >
            <Typography>
              View Text
            </Typography>
          </Button>
        }

        <a href={this.state.downloadUrl}>
          <Button
            id="DownloadButton"
            variant="contained"
            className={styles.button}
            color="primary"
          >
            <Typography>
              Open / Save As
            </Typography>
          </Button>
        </a>
        {this.props.deleteS3Item != null && this.state.userRole.includes('PCMAdmin') && <Button
          variant="contained"
          color="secondary"
          className={styles.button}
          id="deleteRlItemButton"
          onClick={() => this.props.deleteS3Item(this.props.id)}
        >
          <Typography>
            Delete
          </Typography>
        </Button>}
      </Paper>
    );
  }
}

export default withStyles(styles)(S3Item)

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
};

interface TransferListProps {
  updateEvent: any,
  left: any,
  right: any,
}
interface TransferListState {
  checked: any,
  setChecked: any,
  left: any,
  right: any,
  idLayerName: string,
}

class TransferList extends React.Component <TransferListProps, TransferListState> {
  constructor(props: any) {
    super(props);
    console.log('props', this.props)
    this.state = {
      checked: [],
      setChecked: [],
      left: this.props.left,
      right: this.props.right,
      idLayerName: 'Bubble Numbers',
    };
  }

  componentDidMount() {
    console.log('inside transferlist', this.props, this.state)
  }

  not = (a: string[], b: string[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  intersection = (a: string[], b: string[]) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  union = (a: string[], b: string[]) => {
    return [...a, ...this.not(b, a)];
  }
  handleToggle = (value: string) => () => {
    const currentIndex = this.state.setChecked.indexOf(value);
    const newChecked = [...this.state.setChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({setChecked: newChecked});
  };
  handleToggleAll = (items: string[]) => () => {
    if (this.numberOfChecked(items) === items.length) {
      this.setState({setChecked: this.not(this.state.setChecked, items)});
    } else {
      this.setState({setChecked: this.union(this.state.setChecked, items)});
    }
  };
  handleCheckedRight = () => {
    const leftChecked = this.intersection(this.state.setChecked, this.state.left);
    const update = {
      right: (this.state.right.concat(leftChecked)),
      left: (this.not(this.state.left, leftChecked)),
      setChecked: (this.not(this.state.setChecked, leftChecked))
    };
    this.setState(update);
    this.props.updateEvent(update.left);
  };
  handleCheckedLeft = () => {
    const rightChecked = this.intersection(this.state.setChecked, this.state.right);
    const update = {
      left: this.state.left.concat(rightChecked),
      right: this.not(this.state.right, rightChecked),
      setChecked: this.not(this.state.setChecked, rightChecked),
    }
    this.setState(update);
    this.props.updateEvent(update.left);
  };
  numberOfChecked = (items: string[]) => {
    return this.intersection(this.state.setChecked, items).length;
  }

  customList = (title: React.ReactNode, items: string[]) => {
    //@ts-ignore
    const styles = this.props.classes;
    return (
    <Card>
      <CardHeader
        className={styles.cardHeader}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={this.numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />

      <Divider />
      <List className={styles.list} dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={this.handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={this.state.setChecked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.slice(0, -4)} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  )};

  render() {
    const rightChecked = this.intersection(this.state.setChecked, this.state.right);
    const leftChecked = this.intersection(this.state.setChecked, this.state.left);

    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container>
        <Grid container spacing={2} justify="center" alignItems="center" className={styles.root}>
        <Grid item>{this.customList('Layers to Display', this.state.left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={styles.button}
              onClick={this.handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={styles.button}
              onClick={this.handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{this.customList('Undisplayed Layers', this.state.right)}</Grid>
        </Grid>
      </Container>
    );
  };
}

export default withStyles(styles)(TransferList);

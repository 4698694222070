import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ProjectDisplay from './projectDisplay'
import ProjectDetailedDisplay from './projectDetailedDisplay';
import { API, graphqlOperation } from 'aws-amplify'
import { listProjects } from '../graphql/queries';

interface projectDisplayContainerProps {
  setActiveScreen: any,
  updateSidebar: any,
  contractId: String,
  company: String,
  id: String,
}
interface projectDisplayContainerState {
  projectDisplayId: String,
  projects: Array<any>,
}

export default class ProjectDisplayContainer extends React.Component <projectDisplayContainerProps, projectDisplayContainerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      projectDisplayId: "",
      projects: [],
    };
  }

  async componentDidMount() {
    await API.graphql(graphqlOperation(
      listProjects,
      {filter: { contractId: {eq: this.props.contractId}}},
      // @ts-ignore
    )).then((results: any) => {
      const projects = results.data.listProjects.items;
      this.setState({projects});
    }).catch((err :any) => {
      console.error('Error listing projects', err);
    });
  }

  setDisplayProjectDetails = (id: String) => {  //TODO add this to onclick
    this.setState({
      projectDisplayId: id,
    });
  }

  displayProjectDetails = () => {
    return (
      <Container>
        <ProjectDetailedDisplay
          project={this.state.projects.filter((proj: any) => this.state.projectDisplayId === proj.id)}
          setActiveScreen={this.props.setActiveScreen}
          updateSidebar={this.props.updateSidebar}
        />
      </Container>
    );
  }

  render() {
    return (
      <Container>
      {this.state.projectDisplayId === "" && <Container>
        <Typography id="projectDisplayHeader">Projects</Typography>
        {this.state.projects.map((element: any, index: number) =>
          <ProjectDisplay key={index} project={element} setActiveScreen={this.props.setActiveScreen} updateSidebar={this.props.updateSidebar}/>
        )}
        {console.log('projectDisplayContainerProps', this.props)}
        <Button id={'newContractButton'} onClick={() => {this.props.setActiveScreen('newProjectForm', {contractId: this.props.contractId, company: this.props.company})}}>
          Create New Project
        </Button>
      </Container>}
      </Container>
    );
  };
}

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SiteDisplay from '../displayContainers/siteDisplay'
import { listSites } from "../graphql/queries";
import { API, Auth, graphqlOperation } from 'aws-amplify';
import JWTDecode from 'jwt-decode';
// import ProjectDetailedDisplay from './projectDetailedDisplay';

interface userSiteViewProps {
  setActiveScreen: any,
}
interface userSiteViewState {
  sites: any,
  userRole: Array<string>,
}

export default class UserSiteView extends React.Component <userSiteViewProps, userSiteViewState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sites: [],
      userRole: [],
    };
  }

  componentDidMount = () => {
    console.log('siteDisplayContainer props', this.props)
    API.graphql(
      graphqlOperation(
        listSites
      )
      // @ts-ignore
    ).then((sites: any) => {
      this.setState({sites: sites.data.listSites.items});
      console.log('sites:', sites);
    }).catch((err: any) => {
      if(err.data.listSites) {
        this.setState({sites: err.data.listSites.items});
      } else {
        console.log('Error listSites:', err);
      }
    });
    Auth.currentAuthenticatedUser().then((currentUser) => {
      currentUser.getSession((err: any, session: any) => {
        if(err) {
          console.error('error getting session', err);
          return;
        }
        const sessionIdInfo = JWTDecode(session.getIdToken().jwtToken);
        // @ts-ignore
        this.setState({userRole: sessionIdInfo['cognito:groups']});
      });
    }).catch((err) => {
      console.error('Error retrieving currentAuthenticatedUser', err);
    });
  }

  render() {
    return (
      <Container>
        <Typography id="userSiteDisplayHeader">Sites</Typography>
        {this.state.sites && Array.isArray(this.state.sites) && this.state.sites.map((element: any, index: number) =>
          <SiteDisplay
            key={index}
            userRole={this.state.userRole}
            site={element}
            setActiveScreen={this.props.setActiveScreen}
            updateSidebar={() => {}}
          />
        )}
      </Container>
    );
  }
}

import { withStyles } from "@material-ui/core/styles";
import ContractDisplay from "../displayContainers/contractDisplay";
import Styles from "../styles/displayAndDrawer.style";

interface ContractDisplayProps {
  key: number,
  contract: any,
  setActiveScreen: any,
}

export default withStyles(Styles, { withTheme: true })(ContractDisplay) ;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper'
import Carousel from 'react-material-ui-carousel'
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { listRLItems } from '../graphql/queries';

import RLDisplay from '../displayContainers/rlDisplay';
import ImageLoader from './imageLoader';
import S3Item from './s3Item';
import xml2js from 'xml2js';
//import { updateSite } from '../graphql/mutations';

const styles = {
  root: {
    padding: '10px', //em = dynamic units?
    margin: '10px',
  },
  button: {
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
  input: {
    color: 'black',
  },
};

interface hotPointDisplayPopoutProps {
  closePopout: any
  hotPoint: any
}
interface hotPointDisplayPopoutState {
  rlItem: any,
}

class HotPointDisplayPopout extends React.Component <hotPointDisplayPopoutProps, hotPointDisplayPopoutState> {
  constructor(props: any) {
    super(props);
    this.state = {
      rlItem: null,
    }
  }

  async componentDidMount() {
    console.log('props:', this.props);
    console.log('state:', this.state);
    await API.graphql(graphqlOperation(listRLItems,
      {
        filter: {rlNumber: {eq: this.props.hotPoint.rlNumber}},
        // @ts-ignore
      })).then((rlItem: any) => {
      console.log('got rlItem', rlItem);
      this.setState({rlItem: rlItem.data.listRLItems.items[0]});
    }).catch((err: any) => {
      console.error('Error getting rlItem', err);
    });
  }

  render(){
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container
        id="hotPointDisplayPopout"
        maxWidth={false}
        disableGutters
      >
        <Button
          id='returnToDrawingButton'
          variant='contained'
          color='primary'
          onClick={this.props.closePopout}>
          Return to drawing
        </Button>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          wrap="nowrap"
          spacing={1}
        >
          <Grid item xs={2}>
            <Paper id="leftContent">
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Component Number</InputLabel>
                <Input disabled classes={styles} id="componentNumber" name="componentNumber" type="text" value={this.props.hotPoint.componentNumber ? this.props.hotPoint.componentNumber : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Description</InputLabel>
                <Input disabled classes={styles} id="description" name="description" multiline type="text" value={this.props.hotPoint.description ? this.props.hotPoint.description : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Manufacturer:</InputLabel>
                <Input disabled classes={styles} id="manufacturer" name="manufacturer" type="text" value={this.props.hotPoint.manufacturer ? this.props.hotPoint.manufacturer : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Part #</InputLabel>
                <Input disabled classes={styles} id="partNumber" name="partNumber" type="text" value={this.props.hotPoint.partNumber ? this.props.hotPoint.partNumber : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Electrical Address:</InputLabel>
                <Input disabled classes={styles} id="electricalAddress" name="electricalAddress" type="text" value={this.props.hotPoint.electricalAddress ? this.props.hotPoint.electricalAddress : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Settings:</InputLabel>
                <Input disabled classes={styles} id="settings" name="settings" type="text" value={this.props.hotPoint.settings ? this.props.hotPoint.settings : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Stock Code:</InputLabel>
                <Input disabled classes={styles} id="stockCode" name="stockCode" type="text" value={this.props.hotPoint.stockCode ? this.props.hotPoint.stockCode : 'N/a'}/>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel className={styles.input} htmlFor="name">Component Application</InputLabel>
                <Input disabled classes={styles} id="componentApplication" name="componentApplication" multiline type="text" value={this.props.hotPoint.componentApplication ? this.props.hotPoint.componentApplication : 'N/a'}/>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item={true} xs={8}>
            {this.props.hotPoint.images.items && this.props.hotPoint.images.items.length > 0
              && <Carousel
                autoPlay={false}
              >
                {this.props.hotPoint.images.items.map((s3Item: any) => {
                  return <ImageLoader s3Item={s3Item} />
                })}
              </Carousel>
              || <Typography>No Image Data</Typography>
            }
          </Grid>

          <Grid item xs={2}>
            {this.state.rlItem != null && this.state.rlItem.s3Items.items.map((s3Item: any, index: number) => {
              return (
                <Paper key={index}>
                  <S3Item
                    id={s3Item.id}
                    ownerCognitoIdentityId={s3Item.ownerCognitoIdentityId}
                    objectName={s3Item.objectName}
                    s3ObjectId={s3Item.s3ObjectId}
                    deleteS3Item={null}
                  />
                </Paper>
              )
            })}
          </Grid>
        </Grid>

      </Container>

    );
  }
}

export default withStyles(styles)(HotPointDisplayPopout);

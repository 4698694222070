/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      company
      owner
      contractLead
      projects {
        items {
          id
          createdAt
          updatedAt
          contractId
          name
          company
          projectLead
          projectLeadEmail
          projectLeadPhone
          status
          owner
        }
        nextToken
      }
      status
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      company
      owner
      contractLead
      projects {
        items {
          id
          createdAt
          updatedAt
          contractId
          name
          company
          projectLead
          projectLeadEmail
          projectLeadPhone
          status
          owner
        }
        nextToken
      }
      status
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      name
      company
      owner
      contractLead
      projects {
        items {
          id
          createdAt
          updatedAt
          contractId
          name
          company
          projectLead
          projectLeadEmail
          projectLeadPhone
          status
          owner
        }
        nextToken
      }
      status
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      contractId
      name
      company
      projectLead
      projectLeadEmail
      projectLeadPhone
      sites {
        items {
          id
          createdAt
          updatedAt
          projectId
          name
          usernames
          owner
        }
        nextToken
      }
      status
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      contractId
      name
      company
      projectLead
      projectLeadEmail
      projectLeadPhone
      sites {
        items {
          id
          createdAt
          updatedAt
          projectId
          name
          usernames
          owner
        }
        nextToken
      }
      status
      owner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      contractId
      name
      company
      projectLead
      projectLeadEmail
      projectLeadPhone
      sites {
        items {
          id
          createdAt
          updatedAt
          projectId
          name
          usernames
          owner
        }
        nextToken
      }
      status
      owner
    }
  }
`;
export const createSite = /* GraphQL */ `
  mutation CreateSite(
    $input: CreateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    createSite(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      projectId
      name
      usernames
      users {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      associatedSvgs {
        items {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateSite = /* GraphQL */ `
  mutation UpdateSite(
    $input: UpdateSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    updateSite(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      projectId
      name
      usernames
      users {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      associatedSvgs {
        items {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteSite = /* GraphQL */ `
  mutation DeleteSite(
    $input: DeleteSiteInput!
    $condition: ModelSiteConditionInput
  ) {
    deleteSite(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      projectId
      name
      usernames
      users {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      associatedSvgs {
        items {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createSVG = /* GraphQL */ `
  mutation CreateSVG(
    $input: CreateSVGInput!
    $condition: ModelSVGConditionInput
  ) {
    createSVG(input: $input, condition: $condition) {
      id
      siteId
      name
      createdAt
      updatedAt
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      svgLayers {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateSVG = /* GraphQL */ `
  mutation UpdateSVG(
    $input: UpdateSVGInput!
    $condition: ModelSVGConditionInput
  ) {
    updateSVG(input: $input, condition: $condition) {
      id
      siteId
      name
      createdAt
      updatedAt
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      svgLayers {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteSVG = /* GraphQL */ `
  mutation DeleteSVG(
    $input: DeleteSVGInput!
    $condition: ModelSVGConditionInput
  ) {
    deleteSVG(input: $input, condition: $condition) {
      id
      siteId
      name
      createdAt
      updatedAt
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      svgLayers {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      siteId
      svgId
      name
      svg {
        id
        siteId
        name
        createdAt
        updatedAt
        events {
          nextToken
        }
        svgLayers {
          nextToken
        }
        owner
      }
      namesOfLayersToDisplay
      hotPoints {
        items {
          id
          hotPointId
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      sequenceOfOperations
      owner
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      siteId
      svgId
      name
      svg {
        id
        siteId
        name
        createdAt
        updatedAt
        events {
          nextToken
        }
        svgLayers {
          nextToken
        }
        owner
      }
      namesOfLayersToDisplay
      hotPoints {
        items {
          id
          hotPointId
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      sequenceOfOperations
      owner
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      siteId
      svgId
      name
      svg {
        id
        siteId
        name
        createdAt
        updatedAt
        events {
          nextToken
        }
        svgLayers {
          nextToken
        }
        owner
      }
      namesOfLayersToDisplay
      hotPoints {
        items {
          id
          hotPointId
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      sequenceOfOperations
      owner
    }
  }
`;
export const createHotPointEventLink = /* GraphQL */ `
  mutation CreateHotPointEventLink(
    $input: CreateHotPointEventLinkInput!
    $condition: ModelHotPointEventLinkConditionInput
  ) {
    createHotPointEventLink(input: $input, condition: $condition) {
      id
      hotPointId
      eventId
      hotPoint {
        id
        createdAt
        updatedAt
        componentNumber
        description
        manufacturer
        partNumber
        stockCode
        electricalAddress
        componentApplication
        rlNumber
        rlItem {
          id
          createdAt
          updatedAt
          rlNumber
          manufacturer
          description
          name
          category
          owner
        }
        settings
        referenceTag
        images {
          nextToken
        }
        events {
          nextToken
        }
        owner
      }
      event {
        id
        createdAt
        updatedAt
        siteId
        svgId
        name
        svg {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        namesOfLayersToDisplay
        hotPoints {
          nextToken
        }
        sequenceOfOperations
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateHotPointEventLink = /* GraphQL */ `
  mutation UpdateHotPointEventLink(
    $input: UpdateHotPointEventLinkInput!
    $condition: ModelHotPointEventLinkConditionInput
  ) {
    updateHotPointEventLink(input: $input, condition: $condition) {
      id
      hotPointId
      eventId
      hotPoint {
        id
        createdAt
        updatedAt
        componentNumber
        description
        manufacturer
        partNumber
        stockCode
        electricalAddress
        componentApplication
        rlNumber
        rlItem {
          id
          createdAt
          updatedAt
          rlNumber
          manufacturer
          description
          name
          category
          owner
        }
        settings
        referenceTag
        images {
          nextToken
        }
        events {
          nextToken
        }
        owner
      }
      event {
        id
        createdAt
        updatedAt
        siteId
        svgId
        name
        svg {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        namesOfLayersToDisplay
        hotPoints {
          nextToken
        }
        sequenceOfOperations
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteHotPointEventLink = /* GraphQL */ `
  mutation DeleteHotPointEventLink(
    $input: DeleteHotPointEventLinkInput!
    $condition: ModelHotPointEventLinkConditionInput
  ) {
    deleteHotPointEventLink(input: $input, condition: $condition) {
      id
      hotPointId
      eventId
      hotPoint {
        id
        createdAt
        updatedAt
        componentNumber
        description
        manufacturer
        partNumber
        stockCode
        electricalAddress
        componentApplication
        rlNumber
        rlItem {
          id
          createdAt
          updatedAt
          rlNumber
          manufacturer
          description
          name
          category
          owner
        }
        settings
        referenceTag
        images {
          nextToken
        }
        events {
          nextToken
        }
        owner
      }
      event {
        id
        createdAt
        updatedAt
        siteId
        svgId
        name
        svg {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        namesOfLayersToDisplay
        hotPoints {
          nextToken
        }
        sequenceOfOperations
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createHotPoint = /* GraphQL */ `
  mutation CreateHotPoint(
    $input: CreateHotPointInput!
    $condition: ModelHotPointConditionInput
  ) {
    createHotPoint(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      componentNumber
      description
      manufacturer
      partNumber
      stockCode
      electricalAddress
      componentApplication
      rlNumber
      rlItem {
        id
        createdAt
        updatedAt
        rlNumber
        manufacturer
        description
        name
        category
        s3Items {
          nextToken
        }
        owner
      }
      settings
      referenceTag
      images {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      events {
        items {
          id
          hotPointId
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateHotPoint = /* GraphQL */ `
  mutation UpdateHotPoint(
    $input: UpdateHotPointInput!
    $condition: ModelHotPointConditionInput
  ) {
    updateHotPoint(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      componentNumber
      description
      manufacturer
      partNumber
      stockCode
      electricalAddress
      componentApplication
      rlNumber
      rlItem {
        id
        createdAt
        updatedAt
        rlNumber
        manufacturer
        description
        name
        category
        s3Items {
          nextToken
        }
        owner
      }
      settings
      referenceTag
      images {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      events {
        items {
          id
          hotPointId
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteHotPoint = /* GraphQL */ `
  mutation DeleteHotPoint(
    $input: DeleteHotPointInput!
    $condition: ModelHotPointConditionInput
  ) {
    deleteHotPoint(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      componentNumber
      description
      manufacturer
      partNumber
      stockCode
      electricalAddress
      componentApplication
      rlNumber
      rlItem {
        id
        createdAt
        updatedAt
        rlNumber
        manufacturer
        description
        name
        category
        s3Items {
          nextToken
        }
        owner
      }
      settings
      referenceTag
      images {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      events {
        items {
          id
          hotPointId
          eventId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createRLItem = /* GraphQL */ `
  mutation CreateRLItem(
    $input: CreateRLItemInput!
    $condition: ModelRLItemConditionInput
  ) {
    createRLItem(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rlNumber
      manufacturer
      description
      name
      category
      s3Items {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateRLItem = /* GraphQL */ `
  mutation UpdateRLItem(
    $input: UpdateRLItemInput!
    $condition: ModelRLItemConditionInput
  ) {
    updateRLItem(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rlNumber
      manufacturer
      description
      name
      category
      s3Items {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteRLItem = /* GraphQL */ `
  mutation DeleteRLItem(
    $input: DeleteRLItemInput!
    $condition: ModelRLItemConditionInput
  ) {
    deleteRLItem(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      rlNumber
      manufacturer
      description
      name
      category
      s3Items {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createS3ItemSVGLink = /* GraphQL */ `
  mutation CreateS3ItemSVGLink(
    $input: CreateS3ItemSVGLinkInput!
    $condition: ModelS3ItemSVGLinkConditionInput
  ) {
    createS3ItemSVGLink(input: $input, condition: $condition) {
      id
      s3ItemId
      svgId
      s3Item {
        id
        parentId
        type
        createdAt
        updatedAt
        ownerCognitoIdentityId
        objectName
        s3ObjectId
        owner
      }
      svg {
        id
        siteId
        name
        createdAt
        updatedAt
        events {
          nextToken
        }
        svgLayers {
          nextToken
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateS3ItemSVGLink = /* GraphQL */ `
  mutation UpdateS3ItemSVGLink(
    $input: UpdateS3ItemSVGLinkInput!
    $condition: ModelS3ItemSVGLinkConditionInput
  ) {
    updateS3ItemSVGLink(input: $input, condition: $condition) {
      id
      s3ItemId
      svgId
      s3Item {
        id
        parentId
        type
        createdAt
        updatedAt
        ownerCognitoIdentityId
        objectName
        s3ObjectId
        owner
      }
      svg {
        id
        siteId
        name
        createdAt
        updatedAt
        events {
          nextToken
        }
        svgLayers {
          nextToken
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteS3ItemSVGLink = /* GraphQL */ `
  mutation DeleteS3ItemSVGLink(
    $input: DeleteS3ItemSVGLinkInput!
    $condition: ModelS3ItemSVGLinkConditionInput
  ) {
    deleteS3ItemSVGLink(input: $input, condition: $condition) {
      id
      s3ItemId
      svgId
      s3Item {
        id
        parentId
        type
        createdAt
        updatedAt
        ownerCognitoIdentityId
        objectName
        s3ObjectId
        owner
      }
      svg {
        id
        siteId
        name
        createdAt
        updatedAt
        events {
          nextToken
        }
        svgLayers {
          nextToken
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createS3Item = /* GraphQL */ `
  mutation CreateS3Item(
    $input: CreateS3ItemInput!
    $condition: ModelS3ItemConditionInput
  ) {
    createS3Item(input: $input, condition: $condition) {
      id
      parentId
      type
      createdAt
      updatedAt
      ownerCognitoIdentityId
      objectName
      s3ObjectId
      owner
    }
  }
`;
export const updateS3Item = /* GraphQL */ `
  mutation UpdateS3Item(
    $input: UpdateS3ItemInput!
    $condition: ModelS3ItemConditionInput
  ) {
    updateS3Item(input: $input, condition: $condition) {
      id
      parentId
      type
      createdAt
      updatedAt
      ownerCognitoIdentityId
      objectName
      s3ObjectId
      owner
    }
  }
`;
export const deleteS3Item = /* GraphQL */ `
  mutation DeleteS3Item(
    $input: DeleteS3ItemInput!
    $condition: ModelS3ItemConditionInput
  ) {
    deleteS3Item(input: $input, condition: $condition) {
      id
      parentId
      type
      createdAt
      updatedAt
      ownerCognitoIdentityId
      objectName
      s3ObjectId
      owner
    }
  }
`;
export const createSiteUserLink = /* GraphQL */ `
  mutation CreateSiteUserLink(
    $input: CreateSiteUserLinkInput!
    $condition: ModelSiteUserLinkConditionInput
  ) {
    createSiteUserLink(input: $input, condition: $condition) {
      id
      siteId
      userId
      site {
        id
        createdAt
        updatedAt
        projectId
        name
        usernames
        users {
          nextToken
        }
        events {
          nextToken
        }
        associatedSvgs {
          nextToken
        }
        owner
      }
      user {
        id
        ownerCognitoIdentityId
        username
        company
        licenseToken
        firstName
        lastName
        phoneNumber
        email
        companyContracts {
          nextToken
        }
        personalContracts {
          nextToken
        }
        sites {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSiteUserLink = /* GraphQL */ `
  mutation UpdateSiteUserLink(
    $input: UpdateSiteUserLinkInput!
    $condition: ModelSiteUserLinkConditionInput
  ) {
    updateSiteUserLink(input: $input, condition: $condition) {
      id
      siteId
      userId
      site {
        id
        createdAt
        updatedAt
        projectId
        name
        usernames
        users {
          nextToken
        }
        events {
          nextToken
        }
        associatedSvgs {
          nextToken
        }
        owner
      }
      user {
        id
        ownerCognitoIdentityId
        username
        company
        licenseToken
        firstName
        lastName
        phoneNumber
        email
        companyContracts {
          nextToken
        }
        personalContracts {
          nextToken
        }
        sites {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSiteUserLink = /* GraphQL */ `
  mutation DeleteSiteUserLink(
    $input: DeleteSiteUserLinkInput!
    $condition: ModelSiteUserLinkConditionInput
  ) {
    deleteSiteUserLink(input: $input, condition: $condition) {
      id
      siteId
      userId
      site {
        id
        createdAt
        updatedAt
        projectId
        name
        usernames
        users {
          nextToken
        }
        events {
          nextToken
        }
        associatedSvgs {
          nextToken
        }
        owner
      }
      user {
        id
        ownerCognitoIdentityId
        username
        company
        licenseToken
        firstName
        lastName
        phoneNumber
        email
        companyContracts {
          nextToken
        }
        personalContracts {
          nextToken
        }
        sites {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      ownerCognitoIdentityId
      username
      company
      licenseToken
      firstName
      lastName
      phoneNumber
      email
      companyContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      personalContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      sites {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      ownerCognitoIdentityId
      username
      company
      licenseToken
      firstName
      lastName
      phoneNumber
      email
      companyContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      personalContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      sites {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      ownerCognitoIdentityId
      username
      company
      licenseToken
      firstName
      lastName
      phoneNumber
      email
      companyContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      personalContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      sites {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

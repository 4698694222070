import React from 'react';
import Image from 'material-ui-image';
import { Storage } from 'aws-amplify';


interface imageLoaderProps {
  s3Item: any
}
interface imageLoaderState {
  imageUrl: string,
}

export default class ImageLoader extends React.Component <imageLoaderProps, imageLoaderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      imageUrl: '',
    }
  }

  async componentDidMount() {
    const s3Item = this.props.s3Item;
    await Storage.get(s3Item.s3ObjectId, {level: 'protected', identityId: s3Item.ownerCognitoIdentityId}).then((imageUrl: any) =>{
      this.setState({imageUrl});
    }).catch((err) => {
      console.error('Error loading image', err);
    })
  }

  render(){
    return (
      <Image aspectRatio={(10/7)} src={this.state.imageUrl} />
    );
  }
}

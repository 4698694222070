/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      createdAt
      updatedAt
      name
      company
      owner
      contractLead
      projects {
        items {
          id
          createdAt
          updatedAt
          contractId
          name
          company
          projectLead
          projectLeadEmail
          projectLeadPhone
          status
          owner
        }
        nextToken
      }
      status
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        name
        company
        owner
        contractLead
        projects {
          nextToken
        }
        status
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      createdAt
      updatedAt
      contractId
      name
      company
      projectLead
      projectLeadEmail
      projectLeadPhone
      sites {
        items {
          id
          createdAt
          updatedAt
          projectId
          name
          usernames
          owner
        }
        nextToken
      }
      status
      owner
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        contractId
        name
        company
        projectLead
        projectLeadEmail
        projectLeadPhone
        sites {
          nextToken
        }
        status
        owner
      }
      nextToken
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      createdAt
      updatedAt
      projectId
      name
      usernames
      users {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      events {
        items {
          id
          createdAt
          updatedAt
          siteId
          svgId
          name
          namesOfLayersToDisplay
          sequenceOfOperations
          owner
        }
        nextToken
      }
      associatedSvgs {
        items {
          id
          siteId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        projectId
        name
        usernames
        users {
          nextToken
        }
        events {
          nextToken
        }
        associatedSvgs {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getRLItem = /* GraphQL */ `
  query GetRLItem($id: ID!) {
    getRLItem(id: $id) {
      id
      createdAt
      updatedAt
      rlNumber
      manufacturer
      description
      name
      category
      s3Items {
        items {
          id
          parentId
          type
          createdAt
          updatedAt
          ownerCognitoIdentityId
          objectName
          s3ObjectId
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listRLItems = /* GraphQL */ `
  query ListRLItems(
    $filter: ModelRLItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRLItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        rlNumber
        manufacturer
        description
        name
        category
        s3Items {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      ownerCognitoIdentityId
      username
      company
      licenseToken
      firstName
      lastName
      phoneNumber
      email
      companyContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      personalContracts {
        items {
          id
          createdAt
          updatedAt
          name
          company
          owner
          contractLead
          status
        }
        nextToken
      }
      sites {
        items {
          id
          siteId
          userId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerCognitoIdentityId
        username
        company
        licenseToken
        firstName
        lastName
        phoneNumber
        email
        companyContracts {
          nextToken
        }
        personalContracts {
          nextToken
        }
        sites {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

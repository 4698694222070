import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { Storage, Auth, API, graphqlOperation } from 'aws-amplify'
import { updateSite, createS3Item, createSVG } from '../graphql/mutations';
//import { updateSiteAssociatedSvgs } from '../graphql/customMutations'
import { getSite } from '../graphql/queries';
import { deleteSVGFromSite } from '../graphql/graphqlFunctions';
import SVGDisplay from './svgDisplay';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
    width: '100%',
  },
  typography: {
    padding: '10px',
  },
  buttonGroup: {
    width: 'min-content',
  },
  svgContainer: {
    maxWidth: 'inherit',
  }
};

interface SiteManagementProps {
  site: any,
  setActiveScreen: any,
  updateSidebar: any,
  classes: any,
  theme: any,
}
interface SiteManagementState {
  site: any,
  associatedSvgIds: any[],
  associatedSvgNames:  string[],
  usernames: string[],
  displayHotPoints: boolean,
  isEditingUsers: boolean,
  usernamesString: string,
}

class SiteManagement extends React.Component <SiteManagementProps, SiteManagementState> {
  constructor(props: any) {
    super(props);
    this.state = {
      site: this.props.site ? this.props.site : {},
      associatedSvgIds: this.props.site ? this.props.site.associatedSvgIds : [],
      associatedSvgNames:  this.props.site ? this.props.site.associatedSvgNames: [],
      usernames:  this.props.site ? this.props.site.usernames: [],
      displayHotPoints: false,
      isEditingUsers: false,
      usernamesString: this.props.site ? this.props.site.usernames.toString(): '',
    };
  }

  componentDidMount() {
    if(this.props.site) {
      this.reloadSite(this.props.site.id);
    }
    console.log('SiteManagement state', this.state)
  }
  handleChange = (event: any, value: string) => {
      // @ts-ignore
      this.setState({
			//...this.state,
    		[value]: event.target.value
      });
  }
  reloadSite = (id: string) => {
    API.graphql(
      graphqlOperation(
        getSite,
        {
          id: this.state.site.id,
          //eventLimit: 1000,
          //hotPointLimit: 1000,
          //userLimit: 1000,
        }
      )
      // @ts-ignore
    ).then((site: any) => {
      this.setState({site: site.data.getSite});
    }).catch((err: any) => {
      console.log('Error getSite1:', err);
    });
  }
  parseSvg = async (svg: any) => {
    console.log('svg', svg)
    const border = '<rect x="0" y="0" width="100%" height="100%" style="fill:none; stroke:black; stroke-width:1;" />';
    let svgHeader = `<svg version="1.1"> `
    //ignore is made safe by if(typeof reader.result === 'string') in readFile
    // @ts-ignore
    const svgAsString: string = await this.readFile(svg);
    let eventedSvgs: Object[] = [];
    try {
      const rawSvg = new DOMParser().parseFromString(svgAsString, "text/xml").childNodes[1].childNodes;
      let style = "";
      rawSvg.forEach(node => {
        if(node.nodeName === 'style') {//get style.  needs to be on all subSvgs
          style = this.htmlToString(node, true);
          // @ts-ignore
          // if(node.parentNode && node.parentNode.attributes && node.parentNode.attributes.viewBox) {
          //   // @ts-ignore
             svgHeader = svgHeader+ '\n' + border + '\n'
          // }
        }
      });
      rawSvg.forEach(node => {
        if(node.nodeName === 'g') {//filters out non-g elements
          eventedSvgs.push({
            data: svgHeader +'\n'+ style +'\n'+ this.htmlToString(node, true).replace('class="st0"', '') +'\n'+ '</svg>', //need to remove the no display class
            // @ts-ignore id does exist on node
            id: node.id,
          });
        }
      });
    } catch (err) {
      console.error('Error while parsing SVG', err)
    }
    return eventedSvgs;
  };
  readFile = (inputFile: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(inputFile);
      reader.onload = () => {
        if(typeof reader.result === 'string')
          resolve(reader.result);
        else
          reject('Not a String');
      };
    });
  };
  htmlToString = (who: any, deep: boolean) => {
    if(!who || !who.tagName) return '';
    var txt, ax, el = document.createElement("div");
    el.appendChild(who.cloneNode(false));
    txt = el.innerHTML;
    if(deep){
        ax = txt.indexOf('>')+1;
        txt = txt.substring(0, ax)+who.innerHTML+ txt.substring(ax);
    }
    //el = undefined;
    return txt;
  }
  httpGet = async (theUrl: string) => {
    const promise = new Promise((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest(); //not supporting IE 6 or below
      xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState===4 && xmlhttp.status===200) {
          resolve(xmlhttp.responseText);
        }
      }
      xmlhttp.open("GET", theUrl, false);
      xmlhttp.send();
    });
    const data = await promise;
    return data;
  }
  uploadSvg = async (event: any) => {
    const svg = event.target.files[0];
    let dom: any[];
    await this.parseSvg(svg).then((data : any)=>{
      dom = data;
      Auth.currentCredentials().then(async user => {
        console.log('user', user)
        const promises: Promise<string>[] = [];

        await API.graphql(graphqlOperation(
          createSVG,
          {
            input: {
              siteId: this.state.site.id,
              name: svg.name,
              createdAt: Date.now(),
            }
          }
          // @ts-ignore
        )).then((results: any) => {
          console.log('results from createSvg',results);
          const svgFromDb = results.data.createSVG;
          dom.forEach(subSvg => {
            const name = `${svg.name}-${subSvg.id}-${Date.now()}.svg`;
            promises.push(new Promise((resolve, reject) => {
              Storage.put(name, subSvg.data, {level: 'protected', contentType: 'image/svg'}).then(async (data) =>{
                await API.graphql(graphqlOperation(
                  createS3Item,
                  {
                    input: {
                      parentId: svgFromDb.id,
                      type: 'svg',
                      createdAt: Date.now(),
                      ownerCognitoIdentityId: user.identityId,
                      objectName: name,
                      //@ts-ignore
                      s3ObjectId: data.key,
                    }
                  }
                  // @ts-ignore
                )).then((results: any) => {
                    console.log('new S3 item', results.data);
                    resolve(results);
                }).catch((err: any) => {
                  console.log('Error creating s3 item!', err);
                  reject(err);
                });
              }).catch(err => {
                reject(err);
              });
            }));
          });
        }).catch((err: any) => {
          console.error('Error creating svg', err);
        });
        await Promise.all(promises).then(results => {
          console.log('results from all promises', results, this.state.site);
          this.reloadSite(this.state.site.id);
        }).catch(err => {
          console.log('error in all promises:', err);
        });
      }).catch(err => console.log('Error in credentials', err))
    });
  };

  render() {
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Paper
        id="site"
        className={styles.root}
      >
        <Typography
          id="siteDisplayHeader"
          className={styles.typography}
        >
          {`${this.state.site.name}`}
        </Typography>
        <Grid
          className={styles.buttonGroup}
          container
          direction='column'
          alignItems='flex-start'
        >
          <Button
            variant="contained"
            component="label"
            className={styles.button}
          >
            Upload SVG
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={this.uploadSvg}
            />
          </Button>
          <Button
            id="viewEventsButton"
            variant="contained"
            className={styles.button}
            onClick={() => this.props.setActiveScreen('siteDetailedDisplay', {site: this.state.site})}
          >
            Switch to Event Display
          </Button>
          <Button
            id="editUsersButton"
            variant="contained"
            className={styles.button}
            onClick={async() => {
              if(this.state.isEditingUsers) {
                await API.graphql(graphqlOperation(
                  updateSite,
                  {
                    input : {
                      id: this.state.site.id,
                      usernames: this.state.usernamesString.split(','),
                    }
                  }
                  // @ts-ignore
                )).then((updatedSite: any) => {
                    console.log('returnedSite ater updating usernames',updatedSite.data.updateSite)
                    this.setState({usernames: updatedSite.data.updateSite.usernames})
                }).catch((err: any) => {
                  console.log('Error updating site usernames!', err);
                });
              }
              this.setState({isEditingUsers: !this.state.isEditingUsers})
              console.log("usernames:", this.state.usernames)
            }}
          >
            <Typography
              className={styles.typography}
            >
              {this.state.isEditingUsers ? "Save Users" : "Edit Users"}
            </Typography>
          </Button>
        </Grid>
        <Container
          id="svgDisplays"
          className={styles.svgContainer}
        >
        {this.state.isEditingUsers &&
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="name">Users with access</InputLabel>
            <Input
              id="manufacturer"
              name="manufacturer"
              type="text"
              value={this.state.usernamesString}
              onChange={(event) => this.handleChange(event, 'usernamesString')}
            />
          </FormControl>
        }
          {
            this.state.site.associatedSvgs.items && this.state.site.associatedSvgs.items.map((svg: any, index: number) => {
              return (<SVGDisplay
                siteOwnerCognitoIdentityId={this.state.site.ownerCognitoIdentityId}
                key={index}
                siteId={this.state.site.id}
                setActiveScreen={this.props.setActiveScreen}
                deleteSvgCallback={() => this.reloadSite(this.props.site.id)}
                events={(this.state.site.events.items && this.state.site.events.items.length > 0 ) ? this.state.site.events.items.filter((event: any) => event.id === svg.parentId): []}
                svg={svg}
              />);
            })
        }
        </Container>
      </Paper>
    );
  };
}

export default withStyles(styles)(SiteManagement)

import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LaunchIcon from '@material-ui/icons/Launch';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Storage, Auth, API, graphqlOperation } from 'aws-amplify'
import JWTDecode from 'jwt-decode';
import { getHotPointsForSite, getSiteWithCustomLimits, getEventsForSite, getSiteDataOnly } from '../graphql/customQueries';

import HotPointDisplayPopout from '../components/hotPointDisplayPopout';

import ReactHtmlParser from '../react-html-parser/src/HtmlParser';
//@ts-ignore
import {UncontrolledReactSVGPanZoom} from 'react-svg-pan-zoom'
import Box from '@material-ui/core/Box';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
  },
  typography: {
    padding: '10px',
    margin: '10px',
  },
  formControl: {
    padding: '10px 0px',
    margin: '0px 10px',
    width: '100%'
  },
  eventButton: {
    width: '100%',
    padding: '10px',
    margin: '5px 10px',
  },
  spinner:{
    width: 1280,
    height: 720,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

interface SiteDetailedDisplayProps {
  site: any,
  setActiveScreen: any,
  updateSidebar: any,
  classes: any,
  theme: any,
}
interface SiteDetailedDisplayState {
  site: any,
  imageUrl: string,
  userInfo: any,
  open: boolean,
  svgHtml: any,
  displayedEvent: any,
  displayedHotPoints: any,
  cachedEvents: any,
  cachedHotPoints: any,
  xViewBoxSize: number,
  yViewBoxSize: number,
  currentDisplayedHotPoint: any,
  userRole: Array<string>,
  credentials: any,
  svgToDisplay: any,
  currentDisplayedEvents: any,
  svgReadyToDisplay: boolean,
}

class SiteDetailedDisplay extends React.Component <SiteDetailedDisplayProps, SiteDetailedDisplayState> {
  constructor(props: any) {
    super(props);
    this.state = {
      site: this.props.site,
      imageUrl: '',
      userInfo: {},
      open: false,
      svgHtml:'',
      displayedEvent: null,
      displayedHotPoints: [],
      currentDisplayedEvents: [],
      cachedEvents: [],
      cachedHotPoints: [],
      xViewBoxSize : 1224,
      yViewBoxSize: 792,
      currentDisplayedHotPoint: null,
      userRole: [],
      credentials: {},
      svgToDisplay: {},
      svgReadyToDisplay: true,
    };
  }

  async componentDidMount() {
    let site, userRole, credentials;
    site  = await API.graphql(
      graphqlOperation(
        getSiteDataOnly,
        {
          id: this.props.site.id,
        },
      )
      // @ts-ignore
    ).then((results: any) => {
      console.log('got getSiteDataONly results', results);
      return results.data.getSite;
    }).catch((err: any) => {
      console.error('Error getting SiteDataOnly', err)
    });
    userRole = await Auth.currentAuthenticatedUser().then(async (currentUser) => {
      return await currentUser.getSession((err: any, session: any) => {
        if(err) {
          console.error('error getting session', err);
          return;
        }
        const sessionIdInfo = JWTDecode(session.getIdToken().jwtToken);
        // @ts-ignore
        return sessionIdInfo['cognito:groups'];
      });
    }).catch((err) => {
      console.error('Error retrieving currentAuthenticatedUser', err);
    });
    credentials = await Auth.currentCredentials().then((currentCreds) => {
      return currentCreds
    }).catch((err) => {
      console.log('Error fetching credentials!', err);
    });
    this.setState({site, userRole, credentials});
    console.log('site before render', this.state)
  }

  htmlToString = (who: any, deep: boolean) => {
    if(!who || !who.tagName) return '';
    var txt, ax, el = document.createElement("div");
    el.appendChild(who.cloneNode(false));
    txt = el.innerHTML;
    if(deep){
        ax = txt.indexOf('>')+1;
        txt = txt.substring(0, ax)+who.innerHTML+ txt.substring(ax);
    }
    //el = undefined;
    return txt;
  }
  httpGet = async (theUrl: string) => {
    const promise = new Promise((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest(); //not supporting IE 6 or below
      xmlhttp.onreadystatechange = () => {
          if (xmlhttp.readyState==4 && xmlhttp.status==200) {
              resolve(xmlhttp.responseText);
          }
      }
      xmlhttp.open("GET", theUrl, false);
      xmlhttp.send();
    });
    const data = await promise;
    return data;
  }
  generateHTML = (strings: string[]) => {
    console.log('string htmlitizing', strings, strings.length )
    if(strings.length == 0 || strings[0].length == 0) {
      console.log('got nuttin...', strings);
      return "<p>No Image Data for Event!  Set associations in the Editor or talk to your administrator</p>"
    }
    let returnString = '';
    let first = true;
    strings.map((svg: any, index: number) => {
      if(!first) {
        returnString = returnString+svg.slice(svg.indexOf('\n'), -6);
      }
      else {
        returnString = svg.slice(0, -6);
        first = false;
      }
    })
    returnString = returnString + "</svg>"
    console.log('returnin something not trash', returnString);
    return returnString;
  }
  getImage = async (imageId: string, identityId: string) => {
    // @ts-ignore
    await Storage.get(imageId, {level: 'protected', identityId}).then((downloadUrl) => {
      this.setState({imageUrl: downloadUrl as string});
      console.log('Got an image!', this.state.imageUrl);
    }).catch(err => {
      console.error("Error getting URL from S3", err)
    });
  }
  selectAndTransformEvent = async (eventId: string) => {
    //get events first
    //gotta set displayedEvent and displayedHotPoints
    let displayedHotPoints: any, displayedEvent: any;
    await API.graphql(
      graphqlOperation(
        getHotPointsForSite,
        {
          id: this.props.site.id,
          eventFilter: {id: {eq: eventId}},
          //eventLimit: 1,
          hotPointLimit: 1000,
        },
      )
      // @ts-ignore
    ).then((results: any) => {
      console.log('results of getHotPointsForSite',results)
      if(results.data.getSite.events
        && results.data.getSite.events.items[0]
        && results.data.getSite.events.items[0].hotPoints
        && results.data.getSite.events.items[0].hotPoints.items
      ) {
        displayedHotPoints = results.data.getSite.events.items[0].hotPoints.items;
      }
      console.log('setting displayedHotPoints', displayedHotPoints);
    }).catch((err: any) => {
      console.error('Error getting SiteDataOnly', err)
    });


    await API.graphql(
      graphqlOperation(
        getEventsForSite,
        {
          id: this.props.site.id,
          eventFilter: {id: {eq: eventId}},
          svgLayerLimit: 1000,
        },
      )
      // @ts-ignore
    ).then((results: any) => {
      console.log('event from getEventsForSite', results)
      if(results.data.getSite.events
        && results.data.getSite.events.items[0]
      ) {
        displayedEvent = results.data.getSite.events.items[0];
      }
      console.log('setting displayedEvent', displayedEvent);
    }).catch((err: any) => {
      console.error('Error getting SiteDataOnly', err)
    });
    let displayed = true;
    await this.getSvgAsString(displayedEvent.svg, displayedEvent.namesOfLayersToDisplay).then((svgHtml) => {
      const svgToDisplay = ReactHtmlParser(svgHtml, {
         transform: (node: any, index: number) => {

           //dealing with multiple hotpoint cases
           if (
             node.attribs
             && node.attribs.id
             && node.attribs.id.match(/^MTEXT_[0-9]+_$/)
           ) {
             if(displayed) {
               console.log('node case 1', node);
               displayed = false;
             }
               // const matched = node.attribs.href.match(/^activity\/([0-9]+)$/i);
               const componentNumber = node.children[1].children[0].data;
               const transform = node.children[1].attribs.transform;

               return (
                 // TODO make bigger for clicking
                 <g className={'clickable'} transform={transform} key={index} onClick={(e: any) => {
                   this.setCurrentDisplayedHotPoint(componentNumber, transform);
                   console.log('hotpoint info', this.state.displayedHotPoints.find((hp: any) => hp.hotPoint.componentNumber === componentNumber));
                 }}>
                   <text className={node.children[1].attribs.class}>{componentNumber}</text>
                 </g>
               );
             }
           else if (
             node.type === 'tag'
             && node.name === 'text'
             && node.children
             && node.children[0]
             && node.children[0].data
             && node.children[0].data.match(/^\w*/)
             && node.parent.attribs.id
            ) {
                 // console.log("found a bubble transform parent?", node);
                 // const matched = node.attribs.href.match(/^activity\/([0-9]+)$/i);
                 if(displayed) {
                   console.log('node case 2', node);
                   displayed = false;
                 }
                 const componentNumber = node.children[0].data;
                 const transform = node.attribs.transform;

                 return (
                   // TODO make bigger for clicking
                   <g className={'clickable'} transform={transform} key={index} onClick={(e: any) => {
                     this.setCurrentDisplayedHotPoint(componentNumber, transform);
                     console.log('hotpoint info', this.state.displayedHotPoints.find((hp: any) => hp.hotPoint.componentNumber === componentNumber));
                   }}>
                     <text className={node.attribs.class}>{componentNumber}</text>
                   </g>
                 );
               }
           else if(node.name === 'svg') {
             // const tempNode = node;
             // node.attribs.viewBox = "0 0 1224 792";
             console.log('Set svg transform!', node)
             //@ts-ignore
             //return ReactHtmlParser.preprocessNodes(tempNode);
           }
         },
         preprocessNodes: (nodes:any) => {
           //nodes[0].attribs.viewBox = "0 0 1224 792";
           //nodes[0].attribs['viewBox'] = `0 0 ${this.state.xViewBoxSize} ${this.state.yViewBoxSize}`;
           console.log('preprocessNodes', nodes);
           return nodes;
         },
       }
      );
      //@ts-ignore
      this.setState({displayedHotPoints, displayedEvent, svgHtml, svgToDisplay: svgToDisplay[0], svgReadyToDisplay: true});
      console.log('setting State after picking new event from menu', this.state);
    }).catch((err: any) => {
      console.error('Error getSvgAsString', err);
    });

  };
  onSvgChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const svgId = event.target.value;
    const currentDisplayedEvents: Array<any> = [];
    this.state.site.events.items.map((event: any) => {
      if(event.svgId === svgId) {
        currentDisplayedEvents.push(event);
      }
    });

  this.setState({currentDisplayedEvents});

  }
  setCurrentDisplayedHotPoint = (componentNumber: string, transform: string) => {
    const currentDisplayedHotPoint = {
      ...this.state.displayedHotPoints.find((hp: any) => {
        if(hp.hotPoint.componentNumber === componentNumber) {
          //@ts-ignore
          console.log('hit!', hp.hotPoint)
          this.getImage(hp.hotPoint.imageId, hp.hotPoint.ownerCognitoIdentityId);
          return hp;
        }
      }),
      transform,
    }
    console.log('updating current hotpoint', currentDisplayedHotPoint);
    if(!currentDisplayedHotPoint.hotPoint) {
      console.error('No hotPoint info for ', componentNumber);
      return;
    }
    this.setState({currentDisplayedHotPoint});
  }

  getSvgAsString = async (svg: any, svgNamesToDisplay: string[]) => {
    // loop through svg layers, get image as blob, concat and return
    console.log('start getsvgasstring', svg, svgNamesToDisplay)
    const promises: Array<Promise<string>> = []
    svg.svgLayers.items.reverse().map((s3Item: any) => {
      if(svgNamesToDisplay.includes(s3Item.s3ObjectId)) {
          promises.push(new Promise(async (resolve, reject) => {
            await Storage.get(
              s3Item.s3ObjectId,
              {
                level: 'protected',
                identityId: s3Item.ownerCognitoIdentityId,
                download: true,
              }
            ).then(async (results: any) => {
              // following not supported by IE may need to fix
              await results.Body.text().then((blobString: any) => {
                resolve(blobString);
              });
            }).catch((err: any) => {
              reject(err);
            });
          }))
      }
    })
    return await Promise.all(promises).then((results: any) => {
      console.log('promise.all', results)
      return this.generateHTML(results as Array<string>);
    });

  };

  render() {
    //@ts-ignore
    const styles = this.props.classes;
    console.log('state and props during render', this.state, this.props)
    return (
      <Container id='siteDetailedDisplay' maxWidth={false} disableGutters>
        <Grid container id="content">
          <Grid item xs={2} id="leftContentPanel">
            <Grid container direction="column" justify="flex-start" alignItems="stretch" id="leftContentPanelInnerGrid">
              <Grid item>
                <Box id="siteInfo" fontWeight="bold" textAlign="center">
                  <Typography id="headerText" variant='h4' className={styles.typography}>
                    {this.props.site.name}
                  </Typography>
                </Box>
                {!this.state.currentDisplayedHotPoint && <FormControl variant="outlined" className={styles.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Events</InputLabel>
                  <Select
                    id="eventSelect"
                    onChange={(event) => {
                      this.onSvgChange(event);
                    }}
                  >
                    {this.state.site.associatedSvgs
                      && this.state.site.associatedSvgs.items
                      && this.state.site.associatedSvgs.items.map((svg: any, index: number) => {
                      return (
                        <MenuItem key={index} id={`svgMenuItem${index}`} value={svg.id}>{svg.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>}
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  {this.state.currentDisplayedEvents.length > 0 && this.state.currentDisplayedEvents.map((event: any)=>{
                    return <Grid item>
                      <Button className={styles.eventButton} variant="contained" onClick={() => {
                        this.setState({svgReadyToDisplay: false});
                        this.selectAndTransformEvent(event.id);
                      }}>{event.name}</Button>
                    </Grid>
                  })}
                  {this.state.currentDisplayedEvents.length == 0 &&
                  <Typography className={styles.typography}>
                    Select a schematic form the dropdown above
                  </Typography>}
                </Grid>
              </Grid>
              <Grid item>
                {this.state.userRole.includes('PCMAdmin') &&
            <Button
              id={'editSiteButton'}
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={() => {
                this.props.setActiveScreen('siteEditor', {site: this.props.site, updateSidebar: this.props.updateSidebar,})
              }}
            >
              Edit Site
            </Button>
          }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} id="rightContentPanel">
          {this.state.svgReadyToDisplay && <Container id='svgDisplay' disableGutters maxWidth={false} className={styles.root}>
            {this.state.displayedEvent
             && !this.state.currentDisplayedHotPoint
             && this.state.svgHtml !== ''
             && this.state.svgToDisplay
             && <UncontrolledReactSVGPanZoom
                width={1280}
                height={792}
                background='#ffffff'
                detectAutoPan={false}
                scaleFactorMax={5}
                scaleFactorMin={.8}
                scaleFactor={1.2}
                scaleFactorOnWheel={1.1}
                miniatureProps={{position: 'none'}}
              >
               {this.state.svgToDisplay}
              </UncontrolledReactSVGPanZoom>
          }
          { this.state.currentDisplayedHotPoint &&
            this.state.imageUrl &&
            <HotPointDisplayPopout
              hotPoint={this.state.currentDisplayedHotPoint.hotPoint }
              closePopout={() => {this.setState({currentDisplayedHotPoint: null})}}
            />
          }
          </Container>}
          {!this.state.svgReadyToDisplay &&
            <Box className={styles.spinner}>
              <CircularProgress size={100}/>
            </Box>}
          </Grid>
        </Grid>

      </Container>
    );
  };
}

export default withStyles(styles)(SiteDetailedDisplay)

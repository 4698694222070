import React from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LaunchIcon from '@material-ui/icons/Launch';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import NewContract from './forms/newContract';
import NewProject from './forms/newProject';
import NewSite from './forms/newSite';
import NewRlItem from './forms/newRlItem';
import ContractDetailedDisplay from './displayContainers/contractDetailedDisplay';
import SiteDetailedDisplay from './displayContainers/siteDetailedDisplay';
import ProjectDetailedDisplay from './displayContainers/projectDetailedDisplay';
import ContractDisplayContainerWrapper from './wrappers/contractDisplayContainerWrapper';
import SiteManagement from './displayContainers/siteManagement';
import RLContainer from './displayContainers/rlContainer';
import SideBar from './sideBar';
import UserSiteView from './userView/userSiteView';

import JWTDecode from 'jwt-decode';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listContracts } from "./graphql/queries";

interface displayAndDrawerProps {classes: any, theme: any};
interface displayAndDrawerState {
  userInfo: any,
  userRole: Array<string>,
  open: boolean,
  setOpen: boolean,
  creatingHotpoint: boolean,
  toolbarTitle: String,
  activeScreen: String,
  propsToPass: any,
  drawerItems: any
};

export default class DisplayAndDrawer extends React.Component <displayAndDrawerProps, displayAndDrawerState> {
  constructor(props: any) {
		super(props)
    this.state = {
      userInfo: {},
      userRole: [],
      open: false,
      setOpen: false,
      creatingHotpoint: false,
      toolbarTitle: 'Home',
      activeScreen: '',
      propsToPass: {},
      // drawerItems: ['Home', 'ComponentList', 'Sequence of Operations', 'Critical Events'],
      drawerItems: [
        { buttonText: 'Home',
          onClick: () => {this.setActiveScreen('combinedDisplay', this.state.propsToPass)},
          disabled: false,
          icon: 'home',
        },
        { buttonText: 'Component List',
          disabled: true,
          icon: 'home',
        },
        { buttonText: 'Sequence of Operations',
          disabled: true,
          icon: 'home',
        },
        { buttonText: 'Critical Events',
          disabled: true,
          icon: 'home',
        },
      ],
    };
	}

  async componentDidMount() {
    await Auth.currentAuthenticatedUser().then((currentUser) => {
      console.log(currentUser)
      currentUser.getSession((err: any, session: any) => {
        console.log(session)
        if(err) {
          console.error('error getting session', err);
          return;
        }
        const sessionIdInfo: any = JWTDecode(session.getIdToken().jwtToken);
        let activeScreen;
        console.log(sessionIdInfo)
        if (sessionIdInfo['cognito:groups'].includes('PCMAdmin')) {
          activeScreen = 'combinedDisplay';
        } else if (sessionIdInfo['cognito:groups'].includes('users')) {
          activeScreen = 'userSiteView';
        }
        // @ts-ignore
        this.setState({userRole: sessionIdInfo['cognito:groups'], activeScreen});
        console.log('userState', this.state.userRole);
      });
    }).catch((err) => {
      console.error('Error retrieving currentAuthenticatedUser', err);
    });
  }

  reloadContracts() {
    let username;
    Auth.currentAuthenticatedUser().then((userInfo) => {
      username = userInfo.username
    }).catch((err) => {
      console.log('Error fetching currentAuthenticatedUser!', err);
    });
      API.graphql(
        graphqlOperation(
          listContracts,
          {username: username},
        )
        // @ts-ignore
      ).then((contracts: any) => {
        if(contracts.data.listContracts.items !== this.state.userInfo.contracts)
          this.setState({...this.state, userInfo: {...this.state.userInfo, contracts: contracts.data.listContracts.items}, drawerItems: [
            { buttonText: 'Home',
              onClick: () => {this.setActiveScreen('combinedDisplay', this.state.propsToPass)},
              disabled: false,
              icon: 'home',
            },
            { buttonText: 'Component List',
              disabled: true,
              icon: 'home',
            },
            { buttonText: 'Sequence of Operations',
              disabled: true,
              icon: 'home',
            },
            { buttonText: 'Critical Events',
              disabled: true,
              icon: 'home',
            },
          ],});
      }).catch((err: any) => {
        console.log('Error getContract:', err);
      });
  }

  displayMainWindow = (activeScreen: String, propsToPass: any) => {
    switch(activeScreen) {
      case 'combinedDisplay': {
        return (
          <ContractDisplayContainerWrapper id={"contractDisplayContainer"} updateSidebar={this.updateSidebar} setActiveScreen={this.setActiveScreen}/>
        );
      }
      case 'newContractForm': {
        return (<NewContract setActiveScreen={this.setActiveScreen} updateSidebar={this.updateSidebar} />);
      }
      case 'newProjectForm': {
        return (<NewProject company={propsToPass.company} contractId={propsToPass.contractId} setActiveScreen={this.setActiveScreen} updateSidebar={this.updateSidebar} />);
      }
      case 'newSiteForm': {
        return (<NewSite  projectId={propsToPass.projectId} setActiveScreen={this.setActiveScreen} updateSidebar={this.updateSidebar} />);
      }
      case 'contractDetailedDisplay': {
        return (<ContractDetailedDisplay setActiveScreen={this.setActiveScreen} contract={propsToPass.contract} updateSidebar={this.updateSidebar} />);
      }
      case 'projectDetailedDisplay': {
        return (<ProjectDetailedDisplay setActiveScreen={this.setActiveScreen} project={propsToPass.project} updateSidebar={this.updateSidebar} />);
      }
      case 'siteEditor': {
        console.log('siteEditor', propsToPass);
        return (<SiteManagement
          setActiveScreen={this.setActiveScreen}
          site={propsToPass.site}
          updateSidebar={this.updateSidebar}
          classes={this.props.classes}
          theme={this.props.theme}
        />);
      }
      case 'siteDetailedDisplay': {
        console.log('siteDD', propsToPass);
        //this.state = {...this.state, toolbarTitle: "Site Details"};
        return (<SiteDetailedDisplay
          setActiveScreen={this.setActiveScreen}
          site={propsToPass.site}
          updateSidebar={this.updateSidebar}
          classes={this.props.classes}
          theme={this.props.theme}
        />);
      }
      case 'rlEditor': {
        console.log('rlEditor', propsToPass);
        return (<RLContainer
          setActiveScreen={this.setActiveScreen}
          updateSidebar={this.updateSidebar}
          classes={this.props.classes}
          theme={this.props.theme}
        />);
      }
      case 'newRlItemForm': {
        console.log('newrlItemForm', propsToPass);
        return (<NewRlItem
          rlItem={propsToPass.rlItem}
          setActiveScreen={this.setActiveScreen}
          updateSidebar={this.updateSidebar}
        />);
      }
      case 'userSiteView': {
        console.log('userSiteView', propsToPass);
        return (<UserSiteView
          setActiveScreen={this.setActiveScreen}
        />);
      }
    }
  };
  setActiveScreen = (activeScreen: String, propsToPass: any) => {
    this.setState({activeScreen, propsToPass})
  };
  updateSidebar = (drawerElement: string, newDrawerContent: any) => {
    if(!drawerElement) {
      this.setState({drawerItems: newDrawerContent});
    }
  };
  handleDrawerOpen = () => {
    this.setState({open: true});
  };
  handleDrawerClose = () => {
    this.setState({open:false});
  };

  renderPCMAdminView = () => {
    const { classes } = this.props;
    const mainIds = ['Home', 'ComponentList', 'SequenceOfOperations', 'CriticalEvents'];
    return(
      <div>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <Toolbar>
          <Typography id="toolbarTitle" variant="h6" noWrap className={classes.title}>
            {this.state.toolbarTitle}
          </Typography>
          <IconButton
            id="drawerButton"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={this.handleDrawerOpen}
            className={clsx(this.state.open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main

      >
        <div className={classes.drawerHeader} />
        {this.displayMainWindow(this.state.activeScreen, this.state.propsToPass)}
      </main>
      {this.state.open && <SideBar
        open={this.state.open}
        userRole={this.state.userInfo}
        classes={this.props.classes}
        theme={this.props.theme}
        handleDrawerClose={this.handleDrawerClose}
        setActiveScreen={this.setActiveScreen}
      />}
    </div>
    );
  };
  renderUserView = () => {
    const { classes, theme } = this.props;
    return(
      <Container className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar)}
      >
        <Toolbar>
          <Typography id="toolbarTitle" variant="h6" noWrap className={classes.title}>
            {this.state.toolbarTitle}
          </Typography>
          <IconButton
            id="drawerButton"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={this.handleDrawerOpen}
            className={clsx(this.state.open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main
      >
        <div className={classes.drawerHeader} />
        {this.displayMainWindow(this.state.activeScreen, this.state.propsToPass)}
      </main>
      {this.state.open && <SideBar
        open={this.state.open}
        userRole={this.state.userInfo}
        classes={this.props.classes}
        theme={this.props.theme}
        handleDrawerClose={this.handleDrawerClose}
        setActiveScreen={this.setActiveScreen}
      />}
      </Container>
    );
  };
  // printMousePos = (event: any) => {
  //   console.log("clientX: " + event.clientX + " - clientY: " + event.clientY);
  //   if(this.state.creatingHotpoint) {
  //
  //   }
  // }

  render() {
    // document.addEventListener("click", this.printMousePos);
    if(this.state.userRole.includes('PCMAdmin')) {
      return this.renderPCMAdminView();
    } else if(this.state.userRole.includes('users')) {
      return this.renderUserView();
    } else {
      return <div id="error">Contact your administrator</div>
    }
  };
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import S3Item from '../components/s3Item';
import { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import { createRLItem, createS3Item, updateRLItem, deleteS3Item } from '../graphql/mutations';
import { getRLItem } from '../graphql/queries';

const styles = {
  root: {
    padding: '10px', //em = dynamic units?
    margin: '10px',
  },
  button: {
    margin: '10px',
  },
  typography: {
    padding: '10px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

interface NewRlItemProps {
  setActiveScreen: any,
  updateSidebar: any,
  rlItem: any,
}
interface NewRlItemState {
  name: string,
  manufacturer: string,
  description: string,
  category: string,
  s3Items: any,
  rlNumber: string,
  id: string,
  credentials: any,
}

class NewRlItem extends React.Component <NewRlItemProps, NewRlItemState> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: this.props.rlItem ? this.props.rlItem.name : 'New RL Item',
      manufacturer: this.props.rlItem ? this.props.rlItem.manufacturer : '',
      description: this.props.rlItem ? this.props.rlItem.description : '',
      category: this.props.rlItem ? this.props.rlItem.category : '',
      s3Items: this.props.rlItem ? this.props.rlItem.s3Items : {items: []},
      rlNumber: this.props.rlItem ? this.props.rlItem.rlNumber : '',
      id: this.props.rlItem ? this.props.rlItem.id : '',
      credentials: {},
    };
  }

  async componentDidMount () {
    const credentials = await Auth.currentCredentials().then((userCreds) => {
      return userCreds;
    }).catch((err) => {
      console.log('Error fetching credentials!', err);
    });
    console.log('state:', this.state)
    if(this.props.rlItem) { //don't try to get if we have no info/id
    // @ts-ignore
      await API.graphql(graphqlOperation(getRLItem, {id: this.state.id})).then((rlItem: any) => {
        console.log('got rlItem', rlItem);
        this.setState({...rlItem.data.getRLItem, credentials});
      }).catch((err: any) => {
        console.error('Error getting rlItem', err);
      });
    } else {
      this.setState({credentials})
    }
  }

  handleChange = (event: any, value: string) => {
      // @ts-ignore
      this.setState({
			//...this.state,
    		[value]: event.target.value
      });
  }

  updateRlItem = async () => {
    console.log('rlItemState:', this.state)
    const input = {
      id: this.state.id,
      name: this.state.name,
      manufacturer: this.state.manufacturer,
      description: this.state.description,
      category: this.state.category,
      rlNumber: this.state.rlNumber,
      updatedAt: Date.now(),
    }
    const rlItem =  await API.graphql(graphqlOperation(
      updateRLItem, { input }
      // @ts-ignore
      )).then((rlItem: any) => {
        console.log('updated rlItem', rlItem);
        return rlItem;
      }).catch((err: any) => {
        console.log('Error updating rlItem!', err);
        return err;
      }
    );
  }
  createRlItem = async () => {
    const rlItem =  await API.graphql(graphqlOperation(
      createRLItem,
      {
        input: {
          name: this.state.name,
          createdAt: Date.now(),
          manufacturer: this.state.manufacturer,
          description: this.state.description,
          category: this.state.category,
          rlNumber: this.state.rlNumber,
        }}
        // @ts-ignore
      )).then((rlItem: any) => {
        console.log('created new rlItem', rlItem);
        this.setState({id: rlItem.data.createRLItem.id})
        return rlItem;
      }).catch((err: any) => {
        console.log('Error creating new rlItem!', err);
        return err;
      }
    );
  }

  addAttachment = () => {

  }
  uploadMediaToS3 = (event: any) => {
    const image = event.target.files[0];
    Auth.currentCredentials().then(async user => {
      await Storage.put(`${image.name}-${Date.now()}`, image, {level: 'protected'}).then(async (data) => {
        console.log('data:', data);
        // @ts-ignore
        console.log('s3Info pre-create:', image, this.state.id, data.key)
        await API.graphql(graphqlOperation(
          createS3Item, {
            input: {
              objectName: image.name,
              parentId: this.state.id,
              ownerCognitoIdentityId: this.state.credentials.identityId,
              // @ts-ignore
              s3ObjectId: data.key,
            }
          }
          // @ts-ignore
        )).then(async (s3Item: any) => {
          const s3Items = this.state.s3Items;
          s3Items.items.push(s3Item.data.createS3Item);
          this.setState({s3Items});
          console.log('uploaded image: ', data);
          console.log('created s3Item: ', s3Item);
          this.updateRlItem(); //hopefully prevents issues of orphaned images in S3 if creation isn't completed
        }).catch((err: any) => {
          console.error('error in upload of image: ', err);
        });
      }).catch((err) => {
        console.error('Error uploading ', err)
      });
    });
  }
  deleteMediaFromS3 = async (id: string) => {
    Auth.currentCredentials().then(async user => {
      // @ts-ignore
      await API.graphql(graphqlOperation(deleteS3Item, {input: {id}})).then(async (s3Item: any) => {
        await Storage.remove(s3Item.data.deleteS3Item.id, {level: 'protected'}).then(data =>{
          const s3Items = this.state.s3Items.items.filter((el: any) => el !== id);
          this.setState({s3Items});
          this.updateRlItem();
        }).catch(err => {
          console.error('error deleting from s3: ', err);
        });
      }).catch((err: any) => {
        console.error('Error deleting s3Item', err);
      });
    }).catch((err) => {
      console.error('Error getting auth ',err)
    });
  }

  render() {
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container className={styles.root}>
        <Typography variant='h4'className={styles.typography}>New RL Item</Typography>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">RL Item Name</InputLabel>
          <Input id="name" name="name" type="text" value={this.state.name} onChange={(event) => this.handleChange(event, 'name')}/>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">RL Item Category</InputLabel>
          <Input id="category" name="category" type="text" value={this.state.category} onChange={(event) => this.handleChange(event, 'category')}/>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">RL Item Description</InputLabel>
          <Input id="description" name="description" type="text" value={this.state.description} onChange={(event) => this.handleChange(event, 'description')}/>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">RL Item Manufacturer</InputLabel>
          <Input id="manufacturer" name="manufacturer" type="text" value={this.state.manufacturer} onChange={(event) => this.handleChange(event, 'manufacturer')}/>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="name">RL Item Number</InputLabel>
          <Input id="rlNumber" name="rlNumber" type="text" value={this.state.rlNumber} onChange={(event) => this.handleChange(event, 'rlNumber')}/>
        </FormControl>
        <Container>
          {this.state.s3Items && this.state.s3Items.items && this.state.s3Items.items.map((s3Item: any, index: number) => {
            console.log('newRLItem:', this.state.credentials.identityId)
            return(
              <S3Item
                ownerCognitoIdentityId={s3Item.ownerCognitoIdentityId}
                key={index}
                id={s3Item.id}
                objectName={s3Item.objectName}
                s3ObjectId={s3Item.s3ObjectId}
                deleteS3Item={this.deleteMediaFromS3}
              />
            )
          })}
        </Container>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          id="attachmentButton"
          component="label"
          className={styles.button}
        >
          <Typography className={styles.typography}>
            Add Attachment
          </Typography>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={async (evt) => {
              if(this.state.id === '') {
                await this.createRlItem();
              }
              this.uploadMediaToS3(evt)
            }}
          />

        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
          className={styles.button}
          id="submitButton"
          onClick={ async () => {
            let rlItem;
            if(this.state.id !== '') {
              rlItem = await this.updateRlItem();
            } else {
              rlItem = await this.createRlItem();
            }
            console.log('rlItem from dynamo',rlItem);
            try{
              this.props.setActiveScreen("rlEditor", {rlItem});
              console.log("Changing screen to rlEditor", rlItem);
            } catch (err) {
              console.log('Error in onClick', err);
            }
          }} >
        <Typography className={styles.typography}>
          {this.state.id ? 'Update RL Item' : 'Create RL Item'}
        </Typography>
      </Button>
      </Container>
    );
  };
}

export default withStyles(styles)(NewRlItem);

import React from 'react';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createContract } from '../graphql/mutations';

interface newContractProps {
  setActiveScreen: any,
  updateSidebar: any,
}
interface newContractState {
  name: String,
  company: String,
  owner: String,
  contractLead: String,
  status: String,
  credentials: any,
}

export default class NewContract extends React.Component <newContractProps, newContractState> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: "",
      company: "",
      owner: "",
      contractLead: "",
      status: "",
      credentials: {},
    };
  }

  componentDidMount () {
    Auth.currentCredentials().then((credentials) => {
      this.setState({credentials})
    }).catch((err) => {
      console.log('Error fetching credentials!', err);
    });
  }

  handleChange(event: any, value: string) {
      this.setState({
			...this.state,
    		[value]: event.target.value
      });
  }

  createContractWrapper = (state: newContractState) => {
    const input = {
      name: this.state.name,
      company: this.state.company,
      owner: this.state.owner,
      contractLead: this.state.contractLead,
      status: this.state.status,
      createdAt: Date.now(),
    };
    console.log('createcontractwrapper state', this.state.credentials.identityId);
    API.graphql(graphqlOperation(createContract, {
      input
      //@ts-ignore
    })).then((contract: newContractState) => {

    }).catch((err: any) => {
      console.log('Error creating new contract!', err);
    });
  }

  render() {
    return (
      <Container>
        <div>
          <h1 id="newContractHeader">New Contract</h1>
          <form id="contractForm" noValidate onSubmit={(event) => {event.preventDefault(); this.createContractWrapper(this.state);}}>
            <h2>New Contract</h2>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="name">Contract Name</InputLabel>
              <Input id="name" name="name" type="text" value={this.state.name} onChange={(event) => this.handleChange(event, 'name')}/>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="normal">Company</InputLabel>
              <Input id="company" name="company" type="text" value={this.state.company} onChange={(event) => this.handleChange(event, 'company')}/>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="normal">Contract Owner</InputLabel>
              <Input id="owner" name="owner" type="text" value={this.state.owner} onChange={(event) => this.handleChange(event, 'owner')}/>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="normal">Company Lead POC</InputLabel>
              <Input id="contractLead" name="contractLead" type="text" value={this.state.contractLead} onChange={(event) => this.handleChange(event, 'contractLead')}/>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="normal">Status</InputLabel>
              <Input id="status" name="status" type="text" value={this.state.status} onChange={(event) => this.handleChange(event, 'status')}/>
            </FormControl>
            <Button type="submit" onClick={async () => {
              const contract = this.createContractWrapper(this.state);
              this.props.setActiveScreen("combinedDisplay", {contract});
            }} variant="contained" color="primary" size="medium" id="submitButton">
            Create Contract
          </Button>
          </form>
        </div>
      </Container>
    );
  };
}

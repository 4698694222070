import React from 'react';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { createProject } from '../graphql/mutations';
import { getContract } from '../graphql/queries';

interface newProjectProps {
  setActiveScreen: any,
  updateSidebar: any,
  contractId: string,
  company: string,
}
interface newProjectState {
  name: string,
  projectLead: string,
  status: string,
  submitted: boolean,
  credentials: any,
}

export default class NewProject extends React.Component <newProjectProps, newProjectState> {
  constructor(props: any) {
    super(props)
    this.state = {
      name: `New Project with ${this.props.company}`,
      projectLead: "",
      status: "Active",
      submitted: false,
      credentials: {},
    };
  }

  componentDidMount () {
    Auth.currentCredentials().then((credentials) => {
      this.setState({credentials})
    }).catch((err) => {
      console.log('Error fetching credentials!', err);
    });
  }

  handleChange(event: any, value: string) {
      this.setState({
			...this.state,
    		[value]: event.target.value
      });
  }

  createProjectWrapper = async () => {
    console.log('formProps', this.props);
    if(!this.state.submitted) {
      this.setState({submitted: true});
      const project = await API.graphql(graphqlOperation(
        createProject,
        {
          input: {
            name: this.state.name,
            projectLead: this.state.projectLead,
            status: this.state.status,
            contractId: this.props.contractId,
            company: this.props.company,
            createdAt: Date.now(),
          }}
          // @ts-ignore
        )).then((project: newProjectState) => {
        return project;
      }).catch((err: any) => {
        console.log('Error creating new project!', err);
        this.setState({submitted: false});
      });
      return project.data.createProject;
    }
  }

   getContractFromDB = async (contractId: string) => {
    let contract;
    try{
      contract = API.graphql(graphqlOperation(getContract, {id: contractId}));
    } catch (err) {
      console.log('Error getting contract', err)
    }
    return contract;
  }

  render() {
    return (
      <Container>
        <div>
          <h1 id="newProjectHeader">New Project</h1>
          <form id="projectForm" noValidate onSubmit={(event) => {event.preventDefault();}}>
            <h2>New Project</h2>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="name">Project Name</InputLabel>
              <Input id="name" name="name" type="text" value={this.state.name} onChange={(event) => this.handleChange(event, 'name')}/>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="normal">Project Lead POC</InputLabel>
              <Input id="projectLead" name="projectLead" type="text" value={this.state.projectLead} onChange={(event) => this.handleChange(event, 'projectLead')}/>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="normal">Status</InputLabel>
              <Input id="status" name="status" type="text" value={this.state.status} onChange={(event) => this.handleChange(event, 'status')}/>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="medium"
              id="submitButton"
              onClick={ async () => {
                const project = await this.createProjectWrapper();
                console.log('project',project);
                try{
                  this.props.setActiveScreen("projectDetailedDisplay", {project});
                } catch (err) {
                  console.log('Error in onClick', err);
                }
              }} >
            Create Project
          </Button>
          </form>
        </div>
      </Container>
    );
  };
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ProjectDisplayContainer from './projectDisplayContainer';

interface ContractDisplayProps {
  contract: any,
  setActiveScreen: any,
  updateSidebar: any,
}
interface ContractDisplayState {
  userInfo: any,
}

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

export default class ContractDetailedDisplay extends React.Component <ContractDisplayProps, ContractDisplayState> {
  constructor(props: any) {
    super(props);
    this.state = {
      userInfo: {}
    };
    console.log('contractDetailedDisplayConstructor', this.props.contract)
  }

  componentDidMount() {
  }

  render() {
    return (
      <Container id="contract">
        <Typography id="contractDisplayHeader">
          {`${this.props.contract.name} with ${this.props.contract.company}\nCompany POC: ${this.props.contract.contractLead} Status: ${this.props.contract.status}`}
        </Typography>
        <Typography>
          Projects
        </Typography>
        <ProjectDisplayContainer
          id={'projectDisplayContainer'}
          contractId={this.props.contract.id}
          company={this.props.contract.company}
          setActiveScreen={this.props.setActiveScreen}
          updateSidebar={this.props.updateSidebar}
        />
      </Container>
    );
  };
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7ac8359a-069e-4ea4-87b1-0b1c8ecab9cf",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_sdVHNqeMq",
    "aws_user_pools_web_client_id": "5sp8vvsiureq6povjdqrpocj50",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://tsyt43tf3bd4xm4wganx5h43ti.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "arka9prod982bcad2c3d9427e997d697ff95a0a1e181748-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
};

interface ProjectDisplayProps {
  key: number,
  project: any,
  setActiveScreen: any,
  updateSidebar: any,
}
interface ProjectDisplayState {}

class ProjectDisplay extends React.Component <ProjectDisplayProps, ProjectDisplayState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    //@ts-ignore
    const styles = this.props.classes
    return (
      <Container id="project" >
        <Paper
          className={styles.root}
        >
          <Typography
            className={styles.typography}
            variant="body1"
            style={{display: 'inline-block'}}
          >
            {`${this.props.project.name} with ${this.props.project.company}`}
          </Typography>
          <Typography
            className={styles.typography}
            variant="body1"
            style={{display: 'inline-block'}}
          >
            {`POC: ${this.props.project.projectLead} Status: ${this.props.project.status}`}
          </Typography>
          <Button
            id="editButton"
            className={styles.button}
            color="primary"
            variant="contained"
            onClick={() => this.props.setActiveScreen("projectDetailedDisplay",{project: this.props.project})}
          >
            Details
          </Button>
        </Paper>
      </Container>
    );
  };
}

export default withStyles(styles)(ProjectDisplay);

import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ContractDisplayWrapper from '../wrappers/contractDisplayWrapper'
import ContractDetailedDisplay from './contractDetailedDisplay';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listContracts } from "../graphql/queries";

interface contractDisplayContainerProps {
  setActiveScreen: any,
  updateSidebar: any,
  id: String,
}
interface contractDisplayContainerState {
  contractDisplayId: String,
  contracts: any,
}

export default class ContractDisplayContainer extends React.Component <contractDisplayContainerProps, contractDisplayContainerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      contractDisplayId: "",
      contracts: [],
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((userInfo) => {
      API.graphql(
        graphqlOperation(
          listContracts,
          {username: userInfo.username},
        )
        // @ts-ignore
      ).then((contracts: any) => {
        this.setState({contracts: contracts.data.listContracts.items});
      }).catch((err: any) => {
        console.log('Error getContract:', err);
      });
    }).catch((err) => {
      console.log('Error fetching currentAuthenticatedUser!', err);
    });

  }

  setDisplayContractDetails = (id: String) => {  //TODO add this to onclick
    this.setState({
      contractDisplayId: id,
    });
  }

  displayContractDetails = () => {
    const contract = this.state.contracts.filter((el: any) =>  el.id === this.state.contractDisplayId);
    console.log("in contract display container", this.state.contractDisplayId);
    return (
      <Container>
        <ContractDetailedDisplay setActiveScreen={this.props.setActiveScreen} contract={contract[0]} updateSidebar={this.props.updateSidebar} />
      </Container>
    );
  }

  render() {
    return (
      <Container>
      {this.state.contractDisplayId === "" && <Container>
        <div>
          <h1 id="contractsDisplayHeader">Contracts</h1>
          {Array.isArray(this.state.contracts) && this.state.contracts.map((element: any, index: number) =>
            <ContractDisplayWrapper key={index} contract={element} setActiveScreen={this.props.setActiveScreen} updateSidebar={this.props.updateSidebar} />
          )}
        </div>
        <Button id={'newContractButton'} color="primary" onClick={() => {this.props.setActiveScreen('newContractForm')}}>
          Create New Contract
        </Button>
      </Container>}
      </Container>
    );
  };
}

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SiteDisplay from './siteDisplay'
import { listSites } from "../graphql/queries";
import { API, Auth, graphqlOperation } from 'aws-amplify';
import JWTDecode from 'jwt-decode';
// import ProjectDetailedDisplay from './projectDetailedDisplay';

interface siteDisplayContainerProps {
  setActiveScreen: any,
  updateSidebar: any,
  project: any,
  company: String,
  id: String,
  sites: any,
}
interface siteDisplayContainerState {
  userRole: Array<string>,
  sites: any,
}

export default class SiteDisplayContainer extends React.Component <siteDisplayContainerProps, siteDisplayContainerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      userRole: [],
      sites: [],
    };
  }

  componentDidMount = () => {
    console.log('siteDisplayContainer props', this.props)
    API.graphql(
      graphqlOperation(
        listSites,
        {filter: { projectId: {eq: this.props.project.id}}},
      )
      // @ts-ignore
    ).then((sites: any) => {
      this.setState({sites: sites.data.listSites.items});
    }).catch((err: any) => {
      console.log('Error listSites:', err);
    });
    Auth.currentAuthenticatedUser().then((currentUser) => {
      currentUser.getSession((err: any, session: any) => {
        if(err) {
          console.error('error getting session', err);
          return;
        }
        const sessionIdInfo = JWTDecode(session.getIdToken().jwtToken);
        // @ts-ignore
        this.setState({userRole: sessionIdInfo['cognito:groups']});
      });
    }).catch((err) => {
      console.error('Error retrieving currentAuthenticatedUser', err);
    });
  }

  render() {
    return (
      <Container>
        <Typography id="siteDisplayHeader">Sites</Typography>
        {this.state.sites && Array.isArray(this.state.sites) && this.state.sites.map((element: any, index: number) =>
          <SiteDisplay
            key={index}
            userRole={this.state.userRole}
            site={element}
            setActiveScreen={this.props.setActiveScreen}
            updateSidebar={this.props.updateSidebar}
          />
        )}
        <Button id={'newSiteButton'} onClick={() => {this.props.setActiveScreen('newSiteForm', {projectId: this.props.project.id})}}>
          Create New Site
        </Button>
      </Container>
    );
  }
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemText';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

const styles = {
  root: {
    padding: '10px',
    margin: '10px',
  },
  button: {
    margin: '10px',
    marginLeft: 'auto',
  },
  typography: {
    padding: '10px',
  },
};

interface SiteDisplayProps {
  userRole: Array<string>,
  key: number,
  site: any,
  setActiveScreen: any,
  updateSidebar: any,
}
interface SiteDisplayState {}

class SiteDisplay extends React.Component <SiteDisplayProps, SiteDisplayState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    //@ts-ignore
    const styles = this.props.classes;
    return (
      <Container
        id="site"
      >
        <Paper
          className={styles.root}
        >
          <Typography
            id="siteText"
            variant="body1"
            style={{display: 'inline-block'}}
            className={styles.typography}
          >
            {`${this.props.site.name}`}
          </Typography>
          <Button
            id="detailsButton"
            color="primary"
            variant="contained"
            className={styles.button}
            onClick={() => {this.props.setActiveScreen('siteDetailedDisplay', {site: this.props.site, updateSidebar: this.props.updateSidebar,})
          }}>
            Details
          </Button>
          {this.props.userRole.includes('PCMAdmin') && <Button
            id="editButton"
            color="secondary"
            variant="contained"
            className={styles.button}
            onClick={() => {
              console.log('editOnclick', this.props);
              this.props.setActiveScreen('siteEditor', {site: this.props.site, updateSidebar: this.props.updateSidebar,});
            }}>
            Edit Site
          </Button>}
          <List dense={true}>
                {this.props.site
                 && this.props.site.events
                 && this.props.site.events.items
                 && this.props.site.events.items.map((event: any, index: number) => {
                  return (<ListItem key={index}>
                    <ListItemIcon>
                      <AccountTreeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={event.name}
                    />
                  </ListItem>)
                })}
          </List>
        </Paper>
      </Container>
    );
  };
}

export default withStyles(styles)(SiteDisplay);
